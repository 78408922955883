import { put, takeLatest } from 'redux-saga/effects';

import * as actionTypes from 'revv/actions/action-types';
import { IHubLocationRequestParams, ISagaPayload, SagaValue, SagaYield } from 'revv/types';
import {
  loadHubLocationFailure,
  loadHubLocationResponse,
  loadHubsListFailure,
  loadHubsListResponse,
  loadHubTypeFailure,
  loadHubTypeResponse,
  revvAccountSwitcherFailure,
  revvAccountSwitcherResponse,
  updateHubLocationFlag,
  userRequest
} from 'revv/actions';
import { API_ROUTES } from 'revv/utils';
import { API_TYPES } from 'utils/constants';
import { apiCall, defaultRevvHeader } from 'utils/helpers';
import { store } from 'store';

function* loadHubsList(action: ISagaPayload<string>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.hubList.apiPath,
      action: API_ROUTES.hubList.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: { client_id: action.payload }
    });
    if (response.status === 200) {
      yield put(loadHubsListResponse(response.data.data));
    } else {
      yield put(loadHubsListFailure('Error occured on Hub list API.'));
    }
  } catch (error: any) {
    yield put(loadHubsListFailure(error));
  }
}

function* loadHubTypes(action: ISagaPayload<string>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.hubTypes.apiPath,
      action: API_ROUTES.hubTypes.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: { client_id: action.payload }
    });
    if (response.status === 200) {
      yield put(loadHubTypeResponse(response.data.data));
    } else {
      yield put(loadHubTypeFailure('Eror occured on hub types API.'));
    }
  } catch (error: any) {
    yield put(loadHubTypeFailure(error));
  }
}

function* loadHubLocations(action: ISagaPayload<IHubLocationRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.hubLocations.apiPath,
      action: API_ROUTES.hubLocations.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: { client_id: action.payload?.activeClientId },
      data: {
        rallio_franchisor_ids: action.payload?.isFranchisor ? action.payload?.hubIds : undefined,
        rallio_account_ids: !action.payload?.isFranchisor ? action.payload?.hubIds : undefined
        // location_ids:  action.payload?.isFranchisor ? action.payload?.locationIds : undefined
      }
    });
    if (response.status === 200) {
      yield put(loadHubLocationResponse(response.data.data));
      if (response.data.data.length === 0) {
        yield put(updateHubLocationFlag(true));
      } else {
        yield put(updateHubLocationFlag(false));
      }
    } else {
      yield put(loadHubLocationFailure('Error occured on hub locations API.'));
    }
  } catch (error: any) {
    yield put(loadHubLocationFailure(error));
  }
}

function* revvAccountSwitcher(action: ISagaPayload<{ franchisorId: number }>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.revvAccountSwitcher.apiPath,
      action: API_ROUTES.revvAccountSwitcher.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: { franchisor_id: action?.payload?.franchisorId }
    });
    if (response.status >= 400) {
      yield put(revvAccountSwitcherFailure('Oops! Something went wrong'));
    } else {
      let activeUserId = store.getState().revv.login.activeUser?.id;
      store.subscribe(() => {
        activeUserId = store.getState().revv.login.activeUser?.id;
      });
      if (activeUserId) {
        yield put(
          userRequest({
            id: String(activeUserId),
            isLogin: true
          })
        );
      }
      yield put(revvAccountSwitcherResponse(true));
    }
  } catch (error) {
    yield put(revvAccountSwitcherFailure('Oops! Something went wrong'));
  }
}

export function* takeAllFilterRequests() {
  yield takeLatest(actionTypes.LOAD_HUBS_LIST_REQUEST, loadHubsList);
  yield takeLatest(actionTypes.LOAD_HUB_TYPE_REQUEST, loadHubTypes);
  yield takeLatest(actionTypes.LOAD_HUB_LOCATION_REQUEST, loadHubLocations);
  yield takeLatest(actionTypes.REVV_ACCOUNT_SWITCHER_REQUEST, revvAccountSwitcher);
}
