import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import moment from 'moment-timezone';

import { ImageValidation } from 'widgets/Media';
import { CommonFilter } from 'components';
import { getAIEngineChatHistoryRequest, getAIEngineThreadListRequest, setThreadId } from 'actions';
import { useAccountSwitcherData } from 'utils/hooks';
import { IStore, aiEngineContainerTypes } from 'types';
import { chatsBasedOnDateGrouping } from 'utils/helpers';

export const AIEngineFilter = () => {
  const dispatch = useDispatch();
  const { id } = useAccountSwitcherData();
  const { threadList, threadId } = useSelector((state: IStore) => state.aiEngine);

  useEffect(() => {
    dispatch(getAIEngineThreadListRequest({ user_id: id }));
  }, [id]); // eslint-disable-line

  const ChatHistory = useCallback(() => {
    const categorizedChats = chatsBasedOnDateGrouping(threadList);

    const renderChats = (threads: aiEngineContainerTypes.IChatThread[], category: string) => {
      return (
        threads.length >= 1 && (
          <div key={category} className="chat-item ch-options">
            <h2 className="dayStamp">{category}</h2>
            <div className="choGroup">
              {threads.map((it, index) => (
                <h4
                  key={it.id}
                  className={`chat-name ${it.thread_id === threadId ? 'cn-active' : ''}`}
                  onClick={() => {
                    dispatch(setThreadId(it.thread_id));
                    dispatch(getAIEngineChatHistoryRequest(it.thread_id));
                  }}
                >
                  <span className="ch-desc">{it.title || 'New Chat'}</span>
                  <DropdownButton
                    as={ButtonGroup}
                    key={index}
                    id={`dropdown-variants-${index}`}
                    variant={'success'}
                    title={<ImageValidation isImgValid defaultImg="more-ai" customClassname="ai-ch-more" customName={'More'} />}
                    className="ch-more-options"
                  >
                    <Dropdown.Item>
                      <ImageValidation isImgValid defaultImg="delete-lined-red" customClassname="ai-chm-item ai-chm-del" customName={'Delete'} />
                      <span>Delete</span>
                    </Dropdown.Item>
                  </DropdownButton>
                </h4>
              ))}
            </div>
          </div>
        )
      );
    };

    return (
      <div className="chat-history">
        <h2 className="chat-title">Chat History</h2>
        <div className="chat-group">
          {Object.entries(categorizedChats).map(([category, data], index) => (
            <div className="cg-sections" key={`${category}-${index}`}>
              {Array.isArray(data)
                ? renderChats(data, category)
                : Object.entries(data)
                    .sort(([a], [b]) => moment(b, 'MMMM YYYY').valueOf() - moment(a, 'MMMM YYYY').valueOf())
                    .map(([subCategory, subChats]) => renderChats(subChats, subCategory))}
            </div>
          ))}
        </div>
      </div>
    );
  }, [threadList, threadId, dispatch]);

  return <CommonFilter filter={[]} handleChange={() => null} isDateRangeFilterEnable={false} sectionVariant="adv-ov-filter" renderPrefixData={ChatHistory} />;
};
