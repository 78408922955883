import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLocationsTreeRequest, /* operationListsResetAll, */ setMenuAccessibility } from 'actions';
import { activeFilterOptions, customerSendSurveyResponses, customerUpdateResponse, loadHubLocationRequest, onChangeLocation, updateHubLocationFlag } from 'revv/actions';
import { getHubTreeKeys } from 'revv/utils';
import { IStore } from 'types';
import { USER_OWNERSHIP } from 'utils/constants';
import { useAccountSwitcherData } from 'utils/hooks';
import { TeamsFilter, CustomersFilter, DashboardFilter, SurveysFilter, AnalyticsFilter, SettingsFilter, ReportsFilter, RevvLocationsEmployeeFilter } from './revv';
import { SendSurveyModal } from 'revv/components';
import { AdminAreaFilter } from './AdminAreaFilter';
import { LeaderboardFilter } from './revv/leaderboard-filter';
interface IRevvssoFilterProps {
  subNavName?: string;
}

export const RevvssoFilter = ({ subNavName }: IRevvssoFilterProps) => {
  const activeClientId = useSelector((state: IStore) => state.revv.login.activeClientId);
  const isAuthenticated = useSelector((state: IStore) => state.revv.login.isAuthenticated);
  const rallioHubLocationList = useSelector((state: IStore) => state.operationsList.treeData);
  const activeFilter = useSelector((state: IStore) => state.revv.filter.activeFilterOptions);
  const menuAvailablity = useSelector((state: IStore) => state.menuAccessiblity.menuAvailablity);
  const revvReference = useSelector((state: IStore) => state.login.activeUser?.revv_reference);
  const showRevv = useSelector((state: IStore) => state.accountSwitcher.showRevv);
  const isHubLocationListEmpty = useSelector((state: IStore) => state.revv.filter.isHubLocationListEmpty);
  const isRevvConnectedToCurrentFranchisor = useSelector((state: IStore) => state.revv.filter.isRevvConnectedToCurrentFranchisor);
  // const selectedLocation = useSelector((state: IStore) => state.revv.filter.selectedLocation);
  // const highlightedLocation = useSelector((state: IStore) => state.revv.filter.highlightedLocations);
  // const defaultSelectedLocation = useSelector((state: IStore) => state.revv.filter.defaultSelectedLocation);

  const dispatch = useDispatch();

  const { isValidId, id, userOwnership, optionalParams } = useAccountSwitcherData();

  const isHubUser = useMemo(() => userOwnership === USER_OWNERSHIP.FRANCHISOR, [userOwnership]);

  // const locationIds = useMemo(() => {
  //   if (selectedLocation.length) {
  //     return selectedLocation;
  //   } else if (highlightedLocation?.length > 0) {
  //     return highlightedLocation;
  //   } else {
  //     return defaultSelectedLocation;
  //   }
  // }, [defaultSelectedLocation, selectedLocation, highlightedLocation]);

  useEffect(() => {
    return () => {
      // QAA-2672
      // dispatch(operationListsResetAll());
      dispatch(
        onChangeLocation({
          selectedLocations: [],
          highlightHubIdsGroup: {},
          highlightedLocations: [],
          selectedFilterMode: [],
          selectedHubGroup: {},
          validHubs: {},
          validLocations: []
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (revvReference?.id && menuAvailablity?.revv?.login && isAuthenticated && (isRevvConnectedToCurrentFranchisor || showRevv)) {
      dispatch(
        setMenuAccessibility({
          ...menuAvailablity,
          revv: {
            ...menuAvailablity.revv,
            login: false
          }
        })
      );
    }
  }, [revvReference, isAuthenticated, isRevvConnectedToCurrentFranchisor, menuAvailablity, showRevv, dispatch]);

  useEffect(() => {
    if (isValidId && id && userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      dispatch(getLocationsTreeRequest({ franchisor_id: id }));
    }
  }, [id, isValidId, userOwnership, dispatch]);

  useEffect(() => {
    if (isAuthenticated && activeClientId && isValidId && (isRevvConnectedToCurrentFranchisor || showRevv)) {
      const hubIds = isHubUser ? getHubTreeKeys(rallioHubLocationList) : [`${id}`];
      if (hubIds?.length > 0) {
        dispatch(
          loadHubLocationRequest({
            activeClientId,
            hubIds,
            isFranchisor: isHubUser
            // locationIds
          })
        );
      }
    }
  }, [activeClientId, isAuthenticated, isValidId, rallioHubLocationList, isHubUser, showRevv, isRevvConnectedToCurrentFranchisor, id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(updateHubLocationFlag(false));
    };
  }, [dispatch]);

  const renderFilter = (pageName?: string) => {
    switch (pageName) {
      case 'dashboard':
        return <DashboardFilter />;
      case 'surveys':
        return <SurveysFilter />;
      case 'reports':
        return <ReportsFilter />;
      case 'revv_teams':
        return <TeamsFilter />;
      // case 'revv':
      //   if (optionalParams[0] === 'customers') {
      //     return <CustomersFilter />;
      //   } else if (optionalParams[0] === 'locations') {
      //     return <LocationsFilter />;
      //   } else {
      //     return <AdminAreaFilter />;
      //   }
      case 'customers':
        return <CustomersFilter />;
      case 'revv_settings':
        if (optionalParams[0] === 'connection') {
          return <AdminAreaFilter />; // for connections page
        } else {
          return <SettingsFilter />;
        }
      case 'analytics_revv':
        return <AnalyticsFilter />;
      case 'locations':
      case 'employees':
        return <RevvLocationsEmployeeFilter />;
      case 'analytics_leaderboard':
        if (optionalParams[1] === 'locations' || optionalParams[1] === 'teams') {
          return <LeaderboardFilter />;
        } else {
          return null;
        }
      default:
        return null;
    }
  };

  return (
    <>
      <div className={isHubLocationListEmpty ? 'filter-disabled' : ''}>{renderFilter(subNavName)}</div>
      <SendSurveyModal
        open={activeFilter === 'sendsurvey'}
        closeModal={() => {
          dispatch(activeFilterOptions(''));
          dispatch(customerSendSurveyResponses(''));
          dispatch(customerUpdateResponse(''));
        }}
      />
    </>
  );
};
