import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EditorState } from 'draft-js';

import { CustomTooltip } from 'widgets/Tooltip';
import { apFranContainerTypes, IStore, apAccountContainerTypes } from 'types';
import {
  TOOLTIP_PLACEMENT,
  COMMON_SOCIAL_PROVIDER_TYPE,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  LOCALIZE_POST_INFO_TOOLTIP,
  AP_FRAN_PERSONALIZED_OPTION_DATA,
  STRING_WITH_PHONE_NUMBER,
  MG_MEDIA_TYPE
} from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { addPostGetSelectedContentObj, addPostPersonalizedIsEmojiOpen, addPostUpdatePersonalizedTextEditor } from 'actions';
import {
  convertEditorStateToString,
  convertFBTextToEditorState,
  convertStringToEditorState,
  createEmptyEditorState,
  getContentTextWithMaximumLimit,
  handleBeforeInput,
  handleInsertTextInEditor,
  handlePastedText
} from 'utils/helpers';
import { CustomTextEditor } from 'widgets/Text';
import { regexes } from 'widgets/DraftjsHashtagsPlugin/hashtagRegex';

export const CreatePersonalizedVersion = (props: apFranContainerTypes.IAPCreatePersonalVersionProps) => {
  const dispatch = useDispatch();

  const { activeSocialMedia, isPersonalizedDDOpen, setIsPersonalizedDDOpen } = props;

  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const { customText, postStatus, personalizedText } = contentObj;
  const isPersonalizedEmojiOpen = useSelector((state: IStore) => state.addPostFranchisorAction.isPersonalizedEmojiOpen);
  const personalizedEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.personalizedEditorState);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier || false);
  // const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);

  const disableEmoji =
    activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER && personalizedText[activeSocialMedia]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER - 3
      ? true
      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && personalizedText[activeSocialMedia]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM - 3
      ? true
      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN && personalizedText[activeSocialMedia]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN - 3
      ? true
      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && personalizedText[activeSocialMedia]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE - 3
      ? true
      : false;

  const dropdownOption = useMemo(() => {
    if (activeSocialMedia && personalizedText[activeSocialMedia]) {
      return AP_FRAN_PERSONALIZED_OPTION_DATA.reduce((acc: apFranContainerTypes.ICreatorPersonalizedOption[], curr) => {
        acc = [...acc, { ...curr, disabled: personalizedText[activeSocialMedia]?.includes(curr.label) ? true : curr.disabled }];
        return isContentSupplier ? acc.slice(0, 7) : acc;
      }, []);
    }
    return AP_FRAN_PERSONALIZED_OPTION_DATA;
  }, [activeSocialMedia, personalizedText, isContentSupplier]);

  const handleUpdatePersonalizedText = (socialMediaKey: string, socialMediaValue: EditorState) => {
    dispatch(addPostUpdatePersonalizedTextEditor({ key: socialMediaKey, value: socialMediaValue }));
    const socialMediaText = getContentTextWithMaximumLimit(convertEditorStateToString(socialMediaValue) || '', socialMediaKey);
    const regexData = new RegExp(STRING_WITH_PHONE_NUMBER);
    const editorGoogleText = regexData.test(customText[socialMediaKey]);
    const hashTagsLimit = personalizedText[activeSocialMedia].match(regexes.validHashtag) || [];
    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        personalizedText: {
          ...personalizedText,
          [socialMediaKey]: socialMediaText
        },
        postStatus: {
          ...postStatus,
          [socialMediaKey]:
            contentObj && !socialMediaKey
              ? false
              : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE &&
                (editorGoogleText || contentObj.personalizedText[socialMediaKey].includes('[phone_number]') || contentObj.personalizedText[socialMediaKey].includes('[phone]'))
              ? false
              : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
              ? contentObj.facebook_story
                ? selectedMediaList.length
                  ? true
                  : false
                : contentObj?.facebook_reel
                ? selectedMediaList.length && selectedMediaList?.[0].media_type === MG_MEDIA_TYPE.VIDEO
                  ? true
                  : false
                : Boolean(personalizedText[socialMediaKey])
              : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && (!selectedMediaList.length || hashTagsLimit?.length > 30)
              ? false
              : Boolean(personalizedText[socialMediaKey])
        }
      })
    );
  };

  const handleSelectedPersonalizedText = (event: apAccountContainerTypes.IAddPostDurationObj) => {
    const customEditorText = convertEditorStateToString(handleInsertTextInEditor(personalizedEditorState[activeSocialMedia], event.label));
    const contentWithTextLimitation = getContentTextWithMaximumLimit(customEditorText || '', activeSocialMedia);
    const regexData = new RegExp(STRING_WITH_PHONE_NUMBER);
    const editorGoogleText = regexData.test(customText[activeSocialMedia]);
    const updatedEditorState = handleInsertTextInEditor(personalizedEditorState[activeSocialMedia], event.label); // old -  convertStringToEditorState(contentWithTextLimitation);
    const hashTagsLimit = personalizedText[activeSocialMedia].match(regexes.validHashtag) || [];

    dispatch(addPostUpdatePersonalizedTextEditor({ key: activeSocialMedia, value: updatedEditorState }));
    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        personalizedText: {
          ...personalizedText,
          [activeSocialMedia]: contentWithTextLimitation
        },
        postStatus: {
          ...postStatus,
          [activeSocialMedia]:
            contentObj && !activeSocialMedia
              ? false
              : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE &&
                (editorGoogleText || contentObj.personalizedText[activeSocialMedia].includes('[phone_number]') || contentObj.personalizedText[activeSocialMedia].includes('[phone]'))
              ? false
              : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
              ? contentObj.facebook_story
                ? selectedMediaList.length
                  ? true
                  : false
                : contentObj?.facebook_reel
                ? selectedMediaList.length && selectedMediaList?.[0].media_type === MG_MEDIA_TYPE.VIDEO
                  ? true
                  : false
                : Boolean(personalizedText[activeSocialMedia])
              : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && (!selectedMediaList.length || hashTagsLimit?.length > 30)
              ? false
              : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK
              ? Boolean(selectedMediaList.length && selectedMediaList?.[0].media_type === MG_MEDIA_TYPE.VIDEO)
              : Boolean(personalizedText[activeSocialMedia])
        }
      })
    );
    setIsPersonalizedDDOpen(false);
  };

  return (
    <div className={`pvr${!customText[activeSocialMedia] || contentObj?.isPostLock ? ` button-opacity` : ''}`}>
      <div className="form-check">
        <label className="checkbox-item">
          <span className="checkbox-hover">
            <input
              type="checkbox"
              name="localizePost"
              className="option-input click-wave"
              checked={Boolean(personalizedText[activeSocialMedia] || '')}
              disabled={!customText[activeSocialMedia] || contentObj?.isPostLock}
              onChange={(event) => {
                const contentWithTextLimitation = getContentTextWithMaximumLimit(
                  personalizedText[activeSocialMedia] ? personalizedText[activeSocialMedia] : customText[activeSocialMedia],
                  activeSocialMedia
                );
                const validPersonaliedText = event.target.checked ? contentWithTextLimitation : '';
                dispatch(
                  addPostUpdatePersonalizedTextEditor({
                    key: activeSocialMedia,
                    value:
                      activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                        ? convertFBTextToEditorState(validPersonaliedText, selectedFBTagList)
                        : convertStringToEditorState(validPersonaliedText)
                  })
                );
                dispatch(
                  addPostGetSelectedContentObj({
                    ...contentObj,
                    personalizedText: {
                      ...personalizedText,
                      [activeSocialMedia]: validPersonaliedText
                    }
                  })
                );
              }}
            />
          </span>
          <div className="label-txt">
            <span className="cb-label">Localize Post with Unique Fields</span>
            <CustomTooltip
              customPlacement={TOOLTIP_PLACEMENT.TOP}
              customClassname={'custom-tooltip-long-text'}
              customTooltipText={LOCALIZE_POST_INFO_TOOLTIP}
              customInput={() => (
                <div className="info__tool--tip">
                  <ImageValidation isImgValid defaultImg="information-grey" customName={'activate'} />
                </div>
              )}
            />
          </div>
          <span className="checkmark" />
        </label>
      </div>
      {personalizedText[activeSocialMedia] ? (
        <>
          <div className="rcpm-cd" onClick={() => setIsPersonalizedDDOpen(!isPersonalizedDDOpen)}>
            <CustomDropdown
              classNamePrefix="rp-dats dropdown-action-items"
              id="localize-post-insert-fields"
              placeholder={'Select a field below to insert'}
              menuIsOpen={isPersonalizedDDOpen}
              value={{ id: 0, value: '', label: 'Select a field below to insert' }}
              onChange={(event: any) => handleSelectedPersonalizedText(event)}
              options={dropdownOption}
              isOptionDisabled={(option) => option.disabled}
            />
          </div>

          <div className="rcpm-txtArea">
            <div className="at-textarea-holder">
              <CustomTextEditor
                emojiKey="personalized text"
                className="at-textarea"
                placeholder="Type your post content here..."
                editorState={personalizedEditorState[activeSocialMedia] || createEmptyEditorState()}
                onEditorChange={(currentValue) => {
                  handleUpdatePersonalizedText(activeSocialMedia, currentValue);
                }}
                isEmoji={!disableEmoji}
                isEmojiListOpen={isPersonalizedEmojiOpen}
                onEmojiClick={() => {
                  dispatch(addPostPersonalizedIsEmojiOpen(!isPersonalizedEmojiOpen));
                }}
                isMentionEnabled={false}
                handleBeforeInput={() => handleBeforeInput(convertStringToEditorState(personalizedText[activeSocialMedia]) || createEmptyEditorState(), activeSocialMedia || '')}
                handlePastedText={(pastedText) => handlePastedText(pastedText, convertStringToEditorState(personalizedText[activeSocialMedia]) || createEmptyEditorState(), activeSocialMedia || '')}
              />
            </div>
            {personalizedText &&
              [
                COMMON_SOCIAL_PROVIDER_TYPE.TWITTER,
                COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM,
                COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN,
                COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST,
                COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
              ].includes(activeSocialMedia) && (
                <p className="letter-count-text">
                  <span className="l-count">
                    {activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                      ? personalizedText[`twitter`]?.length || 0
                      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                      ? personalizedText[`instagram`]?.length || 0
                      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
                      ? personalizedText[`linkedin`]?.length || 0
                      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST
                      ? personalizedText[`pinterest`]?.length || 0
                      : personalizedText[`google`]?.length || 0}
                  </span>
                  <span className="l-total">
                    {activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                      ? `/${ADD_POST_CONTENT_MAX_TEXT_LENGTH.TWITTER}`
                      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                      ? `/${ADD_POST_CONTENT_MAX_TEXT_LENGTH.INSTAGRAM}`
                      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
                      ? `/${ADD_POST_CONTENT_MAX_TEXT_LENGTH.LINKEDIN}`
                      : activeSocialMedia === COMMON_SOCIAL_PROVIDER_TYPE.PINTEREST
                      ? `/${ADD_POST_CONTENT_MAX_TEXT_LENGTH.PINTEREST}`
                      : `/${ADD_POST_CONTENT_MAX_TEXT_LENGTH.GOOGLE}`}
                  </span>
                </p>
              )}
          </div>
        </>
      ) : null}
    </div>
  );
};
