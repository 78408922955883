import { IAPIRoutes } from 'revv/types';

export const API_ROUTES: IAPIRoutes = {
  signIn: { apiPath: '/users/sign_in', method: 'POST' },
  revvAccountSwitcher: { apiPath: '/users/act_on_behave_of', method: 'POST' },
  userDetail: { apiPath: '/users/:id', method: 'GET' },
  usersMetrics: { apiPath: '/users/metrics', method: 'GET' },
  usersFilteredMetrics: { apiPath: '/users/stats', method: 'POST' },
  usersList: { apiPath: '/users', method: 'GET' },
  usersFilteredList: { apiPath: '/users', method: 'PUT' },
  userCreate: { apiPath: '/users', method: 'POST' },
  userUpdate: { apiPath: '/users/:id', method: 'PATCH' },
  userDelete: { apiPath: '/users/:id', method: 'DELETE' },
  uploadUsers: { apiPath: '/users/import', method: 'POST' },
  profileSettings: { apiPath: '/users/:id/notification_config', method: 'GET' },
  profileSettingsUpdate: { apiPath: '/users/:id/notification_config', method: 'PATCH' },
  clients: { apiPath: '/clients', method: 'GET' },
  clientDetail: { apiPath: '/clients/:id', method: 'GET' },
  updateSetting: { apiPath: '/setting', method: 'PATCH' },
  setting: { apiPath: '/setting', method: 'GET' },
  dashboardSurveyStats: { apiPath: '/dashboard/survey_stats', method: 'POST' },
  surveyStats: { apiPath: '/surveys/stats', method: 'POST' },
  rallioReviewStats: { apiPath: 'reviews/stats', method: 'GET' },
  dashboardRecentComments: { apiPath: '/dashboard/recent_comments', method: 'POST' },
  dashboardSurveyQuestion: { apiPath: '/dashboard/survey_questions', method: 'GET' },
  dashboardSurveyAnswer: { apiPath: '/dashboard/survey_answers', method: 'POST' },
  dashboardSurveyNotifications: { apiPath: '/dashboard/escalated_surveys', method: 'POST' },
  dashboardSurveyResolvedNotifications: { apiPath: '/dashboard/resolved_escalated_surveys', method: 'POST' },
  dashboardResolveNotifications: { apiPath: '/dashboard/resolve_escalated_survey', method: 'POST' },
  dashboardSurveySendMessage: { apiPath: '/dashboard/survey_message', method: 'POST' },
  surveyMetrics: { apiPath: '/surveys/metrics', method: 'POST' },
  sendSurvey: { apiPath: '/surveys', method: 'POST' },
  surveyList: { apiPath: '/surveys', method: 'PUT' },
  sendQueuedSurveys: { apiPath: '/surveys/deliver', method: 'POST' },
  createCustomer: { apiPath: '/customers', method: 'POST' },
  customerDetail: { apiPath: '/customers/:id', method: 'GET' },
  customerDelete: { apiPath: '/customers/:id', method: 'DELETE' },
  updateCustomer: { apiPath: '/customers/:id', method: 'PATCH' },
  getCustomer: { apiPath: '/customers/find', method: 'GET' },
  locationsListFiltered: { apiPath: '/locations', method: 'PUT' },
  locationsList: { apiPath: '/locations', method: 'GET' },
  hubList: { apiPath: '/dashboard/hubs_list', method: 'GET' },
  hubTypes: { apiPath: '/dashboard/hub_types', method: 'GET' },
  hubLocations: { apiPath: '/dashboard/hub_locations', method: 'POST' },
  surveyDetail: { apiPath: '/surveys/:id', method: 'GET' },
  downloadSurveysFiltered: { apiPath: '/surveys/export', method: 'GET' },
  downloadSurveys: { apiPath: '/surveys/export', method: 'GET' },
  pollExport: { apiPath: '/export_requests/:id', method: 'GET' },
  locationsMetrics: { apiPath: '/locations/metrics', method: 'GET' },
  locationsMetricsFiltered: { apiPath: '/locations/stats', method: 'POST' },
  locationCreate: { apiPath: '/locations', method: 'POST' },
  locationUpdate: { apiPath: '/locations/:id', method: 'PATCH' },
  locationDelete: { apiPath: '/locations/:id', method: 'DELETE' },
  locationDetail: { apiPath: '/locations/:id', method: 'GET' },
  uploadLocations: { apiPath: '/locations/import', method: 'POST' },
  customerMetrics: { apiPath: '/customers/stats', method: 'POST' },
  customers: { apiPath: '/customers', method: 'PUT' },
  settingSurveyMessage: { apiPath: '/setting/preview_survey_message', method: 'GET' },
  settingOptInMessage: { apiPath: '/setting/preview_opt_in_message', method: 'GET' },
  settingCouponMessage: { apiPath: '/setting/preview_coupon_message', method: 'GET' },
  settingsEmailMessage: { apiPath: '/setting/preview_survey_email_message', method: 'GET' },
  settingUploadFile: { apiPath: '/s3/sign', method: 'GET' },
  uploadCustomers: { apiPath: '/customers/import', method: 'POST' },
  downloadLocations: { apiPath: '/locations/export', method: 'GET' },
  downloadCustomers: { apiPath: '/customers/export', method: 'POST' },
  downloadUsers: { apiPath: '/users/export', method: 'GET' },
  analyticsSurveyResultPost: { apiPath: '/reports/survey_results', method: 'POST' },
  analyticsSurveyResult: { apiPath: '/reports/survey_results', method: 'GET' },
  analyticsSentimentTimePost: { apiPath: '/reports/sentiment_over_time', method: 'POST' },
  analyticsSentimentTime: { apiPath: '/reports/sentiment_over_time', method: 'GET' },
  analyticsSAvgRating: { apiPath: '/reports/average_rating', method: 'GET' },
  analyticsSAvgRatingPost: { apiPath: '/reports/average_rating', method: 'POST' },
  agreedToReview: { apiPath: '/reports/agreed_to_review', method: 'GET' },
  agreedToReviewPost: { apiPath: '/reports/agreed_to_review', method: 'POST' }
};
