import { settingsPartnerBannersContainerTypes } from 'types';
import { compareTwoObjects } from 'utils';
import { BRAND_PROFILE_SETTINGS_SECTIONS } from 'utils/constants';

export const compareBrandProfileObj = (
  updatedBrandData: Partial<settingsPartnerBannersContainerTypes.IPartnerBannerInitialValues>,
  initialBrandData: Partial<settingsPartnerBannersContainerTypes.IPartnerBannerInitialValues>,
  type: string
) => {
  const getComparisonData = () => {
    switch (type) {
      case BRAND_PROFILE_SETTINGS_SECTIONS.PREFERRED_LANGUAGE: {
        return {
          initialValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.PREFERRED_LANGUAGE]: initialBrandData.preferred_language },
          updatedValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.PREFERRED_LANGUAGE]: updatedBrandData.preferred_language }
        };
      }
      case BRAND_PROFILE_SETTINGS_SECTIONS.POST_CARD_LOGO: {
        return {
          initialValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.POST_CARD_LOGO]: initialBrandData.post_card_logo },
          updatedValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.POST_CARD_LOGO]: updatedBrandData.post_card_logo }
        };
      }
      case BRAND_PROFILE_SETTINGS_SECTIONS.FEED_BADGE_URL: {
        return {
          initialValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.FEED_BADGE_URL]: initialBrandData.feed_badge_url },
          updatedValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.FEED_BADGE_URL]: updatedBrandData.feed_badge_url }
        };
      }
      case BRAND_PROFILE_SETTINGS_SECTIONS.BRAND_LOGO: {
        return {
          initialValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.BRAND_LOGO]: initialBrandData.brand_logo },
          updatedValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.BRAND_LOGO]: updatedBrandData.brand_logo }
        };
      }
      case BRAND_PROFILE_SETTINGS_SECTIONS.BACKGROUND_IMAGE: {
        return {
          initialValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.BACKGROUND_IMAGE]: initialBrandData.background_image },
          updatedValue: { [BRAND_PROFILE_SETTINGS_SECTIONS.BACKGROUND_IMAGE]: updatedBrandData.background_image }
        };
      }
      default: {
        return { initialValue: {}, updatedValue: {} };
      }
    }
  };

  const { initialValue, updatedValue } = getComparisonData();

  return compareTwoObjects(initialValue, updatedValue);
};
