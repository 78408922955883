import { EditorState } from 'draft-js';
import { Dispatch } from 'redux';

import { ChartType, dateTimeFormatter, PLATFORM_FILTER, RatingStatusValue, REVIEW_ANALYTICS_FILTER_INITIAL_VALUE, ReviewAnalyticsStatsValue } from 'analytics/utils';
import { reviewsContainerTypes, reviewsReducerTypes, accountApiTypes, apFranContainerTypes, reviewsApiTypes, commonAccountSwitcher } from 'types';
import { accountPostPermissionRestricted, convertEditorStateToString } from 'utils/helpers';
import { ReviewsStatus, ReviewsInteraction, COMMON_SOCIAL_PROVIDER_TYPE, FRANCHISORS_SELECTIONS, LOCATION_LISTS, SENTIMENTS, UTC_TIMESTAMP_FORMAT } from 'utils/constants';
import { aiContentCreatorReducerTypes } from 'types/';
import { getKeyWordDataRequest } from 'analytics/actions';

export const getReviewsReqParamObj = (filterData: reviewsReducerTypes.IReviewsFilterValue): reviewsContainerTypes.IReviewsFilterReqParams => {
  const filteredRatingList = [
    ...new Set(
      filterData.ratings.reduce((acc: string[], curr) => {
        if (curr === RatingStatusValue.RECOMMENDED) acc = [...acc, RatingStatusValue.FIVE];
        else if (curr === RatingStatusValue.NOT_RECOMMENDED) acc = [...acc, RatingStatusValue.ONE];
        else acc = [...acc, curr];
        return acc;
      }, [])
    )
  ];
  return {
    ...(filterData.platform && filterData.platform === COMMON_SOCIAL_PROVIDER_TYPE.ALL
      ? null
      : {
          network: filterData.platform === PLATFORM_FILTER[7].value ? COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_PLACES : filterData.platform
        }),
    ...(filterData.ratings.length &&
      filterData.ratings.every((datum) => datum === RatingStatusValue.RECOMMENDED || datum === RatingStatusValue.NOT_RECOMMENDED) && { network: COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK }),
    ...(filteredRatingList.length && { ratings: filteredRatingList }),
    ...(filterData.interaction && {
      has_reply: filterData.interaction === ReviewsInteraction.REPLIED_TO ? 1 : 0
    }),
    ...(filterData.status && {
      completed: filterData.status === ReviewsStatus.CLOSE ? 1 : 0
    })
  };
};

export const getHubLocListForDropdown = (locationList: apFranContainerTypes.ILocationSelectionDropdown[], typeName: string): reviewsContainerTypes.IReviewsLocationObj[] => {
  return locationList.reduce((acc: reviewsContainerTypes.IReviewsLocationObj[], curr) => {
    if (typeName === curr.type) {
      const locationObj = {
        id: curr.id,
        value: curr.name,
        label: curr.name,
        ...([FRANCHISORS_SELECTIONS, LOCATION_LISTS].includes(curr.type) && { locationsCount: curr.locationsCount }),
        type: curr.type,
        feed_type: curr.feed_type,
        locked: curr.locked
      };
      acc = [...acc, locationObj];
    }
    return acc;
  }, []);
};

/** Get the average rating series data for chart */
export const getAvgRatingSeriesList = (analyticsStatsByDate: { [key: string]: string | number }[], totalAvgRating: null | number | string) => {
  const avgRatingSeries = analyticsStatsByDate.reduce((acc: { [key: string]: string | number }[], curr) => {
    acc = curr?.avgRatingCount ? [...acc, { y: (+curr.avgRatingCount / 5) * 100, color: '#FDE64B' }] : [...acc];
    return acc;
  }, []);
  // const pastStatsData = avgRatingSeries.slice(Math.max(avgRatingSeries.length - 16, 0));
  /*  const futureStatsData = new Array(4).fill({
    y: typeof totalAvgRating === 'number' && totalAvgRating ? (totalAvgRating / 5) * 100 : 0,
    color: '#D9DDDC'
  }); */
  return avgRatingSeries;
};

/** Reviews - Get account platform list */
export const getAccReviewsPlatformList = (socialMedia: accountApiTypes.ISocialMedia[], yelpUrl: null | string) => {
  const socialMediaList = socialMedia.reduce((acc: string[], curr) => {
    if ([COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK].includes(curr.provider_name)) {
      acc = [...acc, curr.provider_name];
    } else if ([COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_ACCOUNT].includes(curr.provider_name)) {
      acc = [...acc, COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE];
    }
    return acc;
  }, []);
  if (yelpUrl) {
    return [...socialMediaList, COMMON_SOCIAL_PROVIDER_TYPE.YELP];
  } else {
    return [...socialMediaList];
  }
};

export const enableReviewsReplyTab = (isAdmin: boolean, isFranchisor: boolean, isFranchisee: boolean, overriddenReviewResponsesLocked: boolean, reviewData: reviewsApiTypes.IReviewsListObj) => {
  const reviewResponsesRestricted = accountPostPermissionRestricted(isAdmin, isFranchisor, isFranchisee, overriddenReviewResponsesLocked);
  if (!reviewResponsesRestricted) {
    if (reviewData.reply_url && !reviewData.review_reply) {
      if (!reviewData.unreplyable) {
        return true;
      }
    }
  }
  return false;
};

export const getReviewsReplyTemplateDefaultText = (
  reviewsPlatform: string,
  reviewsRating: null | number,
  reviewsRecommended: null | boolean,
  reviewReplyTemplateData: commonAccountSwitcher.IReviewReplyTemplateObj
) => {
  if (reviewReplyTemplateData) {
    if (reviewsPlatform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) {
      switch (reviewsRecommended) {
        case true:
          return reviewReplyTemplateData.reviewReplyTemplateFacebookRecommend;
        case false:
          return reviewReplyTemplateData.reviewReplyTemplateFacebookNotRecommend;
        default:
          return '';
      }
    } else {
      switch (reviewsRating) {
        case 1:
          return reviewReplyTemplateData.reviewReplyTemplate1Star;
        case 2:
          return reviewReplyTemplateData.reviewReplyTemplate2Star;
        case 3:
          return reviewReplyTemplateData.reviewReplyTemplate3Star;
        case 4:
          return reviewReplyTemplateData.reviewReplyTemplate4Star;
        case 5:
          return reviewReplyTemplateData.reviewReplyTemplate5Star;
        default:
          return '';
      }
    }
  }
  return '';
};

export const aiModelReviewLearningPayload = (
  input: string,
  output: EditorState,
  franchisorId: number,
  userName: string,
  sentiment: reviewsApiTypes.IReviewsListObj,
  type: string,
  responseCount: number
) => {
  const splitUserName = userName.split(' ')[0];
  const sentimentData =
    sentiment.rating === 5 || sentiment.recommended
      ? `${SENTIMENTS.POSITIVE}`
      : sentiment.rating === 1 || sentiment.rating === 2 || !sentiment.recommended
      ? `${SENTIMENTS.NEGATIVE}`
      : `${SENTIMENTS.NEUTRAL}`;

  const payload: aiContentCreatorReducerTypes.IAIModelReviewsLearning = {
    data: [
      {
        input,
        output: convertEditorStateToString(output),
        franchisor_id: franchisorId,
        user_name: splitUserName,
        sentiment: sentimentData,
        type,
        response_count: responseCount
      }
    ]
  };
  return payload;
};

export const dispatchKeyWords = (
  id: number,
  page: number,
  isAscending: boolean,
  sortKey: string,
  selectedHubKey: string,
  selectedHubId: string | number,
  platform: string,
  dispatch: Dispatch<any>,
  startDate?: string,
  endDate?: string
) => {
  if (id) {
    const formatterStartDate = startDate ? dateTimeFormatter(startDate, UTC_TIMESTAMP_FORMAT, 'startOfDay') : REVIEW_ANALYTICS_FILTER_INITIAL_VALUE.dateRange.fromDate.timeStamp;
    const formatterEndDateDate = endDate ? dateTimeFormatter(endDate, UTC_TIMESTAMP_FORMAT, 'endOfDay') : REVIEW_ANALYTICS_FILTER_INITIAL_VALUE.dateRange.toDate.timeStamp;
    const data = {
      date_range: [formatterStartDate || '', formatterEndDateDate || ''],
      [selectedHubKey]: selectedHubId,
      page,
      isKeywordAscending: isAscending,
      sortKey,
      ...(platform && { network: platform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE_PLACES : platform }),
      fetchAllKeywords: true
    };
    dispatch(getKeyWordDataRequest(data));
  }
};

export const selectedChartType = (stat: string, isHubUser: boolean) => {
  switch (stat) {
    case ReviewAnalyticsStatsValue.REVIEWS: {
      return isHubUser ? ChartType.BAR : 'totalCount';
    }
    case ReviewAnalyticsStatsValue.AVERAGE_RATING: {
      return ChartType.COLUMN;
    }
    case ReviewAnalyticsStatsValue.KEYWORDS: {
      return 'wordCloud';
    }
    default: {
      return 'areaSpline';
    }
  }
};
