import moment from 'moment-timezone';

import { config } from 'config';
import { aiEngineContainerTypes } from 'types';

export const chatsBasedOnDateGrouping = (threads: aiEngineContainerTypes.IChatThread[]): aiEngineContainerTypes.CategorizedChats => {
  const now = moment();
  const categories: aiEngineContainerTypes.CategorizedChats = {
    Today: [],
    Yesterday: [],
    'Previous 7 Days': [],
    'Previous 30 Days': [],
    Months: {},
    Years: {}
  };

  if (threads) {
    threads.forEach((chat) => {
      const chatDate = moment(chat.created_at);
      const yearLabel = chatDate.format('YYYY');
      const monthLabel = chatDate.format('MMMM');

      if (chatDate.isSame(now, 'day')) {
        categories.Today.push(chat);
      } else if (chatDate.isSame(now.clone().subtract(1, 'days'), 'day')) {
        categories.Yesterday.push(chat);
      } else if (chatDate.isAfter(now.clone().subtract(7, 'days'))) {
        categories['Previous 7 Days'].push(chat);
      } else if (chatDate.isAfter(now.clone().subtract(30, 'days'))) {
        categories['Previous 30 Days'].push(chat);
      } else if (chatDate.isSame(now, 'year')) {
        if (!categories.Months[monthLabel]) categories.Months[monthLabel] = [];
        categories.Months[monthLabel].push(chat);
      } else {
        if (!categories.Years[yearLabel]) categories.Years[yearLabel] = [];
        categories.Years[yearLabel].push(chat);
      }
    });
  }

  return categories;
};
export const imageFileDownload = (fileId: string) => {
  return `${config.apiAiEngineBaseUrl}file/download/${fileId}`;
};

export const modifiedMessages = (content: any, assistantResponse?: string) => {
  let newResponse = assistantResponse || '';

  if (content.type === 'text') {
    if (content.text.value !== null) {
      newResponse += content.text.value;
    }
    if (content.text.annotations?.length) {
      for (const annotationData of content.text.annotations) {
        if (newResponse?.includes(annotationData.text)) {
          const base64 = imageFileDownload(annotationData.file_path.file_id);
          const escapedText = annotationData.text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          const markdownImagePattern = new RegExp(`!\\[.*?\\]\\(${escapedText}\\)`, 'g');

          if (markdownImagePattern.test(newResponse)) {
            // newResponse = newResponse.replace(tempData, "");
            // setTempData('')
          }
          newResponse = newResponse.replace(annotationData.text, `${base64}`);
        }
      }
    }
  } else if (content.type === 'image_file') {
    const base64 = imageFileDownload(content.image_file.file_id);
    // tempData = (`![Alt Text](${base64})\n\n`);
    newResponse += `![Alt Text](${base64})\n\n`;
  }

  return newResponse || '';
};
