import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import parse from 'html-react-parser';

import { PLATFORM_FILTER } from 'analytics/utils';
import { IStore, calendarContainerTypes } from 'types';
import {
  calendarConvertDateBasedOnTimeZone,
  calendarInitScheduledTime,
  calendarUTCtoLocalHandler,
  canApproveScheduledPost,
  canEditScheduledPost,
  canEditScheduledPostDateAndTime,
  checkSiteRestriction,
  convertURLAsLink,
  dateObjBasedOnTimeZone,
  getAddMediaListForCreator,
  getCorporatePostDetail,
  getCreatorLinkData,
  getEditOrSchedulePost,
  getPermittedImgsBasedOnPlatform,
  getPostContentTextField,
  getTimezoneAbbreviation,
  getUpdatedTagsList,
  isSocialMediaActive
} from 'utils/helpers';
import {
  ALERT_BOX_TYPES,
  AP_ACTION_TYPES,
  AP_FRAN_SHOW_LINE_BREAK_TYPES,
  AP_SAVED_POST_INIT_DETAILS,
  CALENDAR_DATE_FORMAT,
  COMMON_SOCIAL_PROVIDER_TYPE,
  CONNECT_SOCIAL_MEDIA_TO_APPROVE_THE_POST,
  CreatorInstaPhotoType,
  EMAIL_DATE_FORMAT,
  GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS,
  LOCATION_TIME_ZONE_DATE_TIME_FORMAT,
  NoDataFoundProps,
  RIPPLE_COLOR,
  SCHEDULE_PARAM_TYPE,
  USER_OWNERSHIP
} from 'utils/constants';
import { useAccountSwitcherData, useAvailablePlatforms, useSiteRestriction } from 'utils/hooks';
import { CarousalImages, ImageValidation, Video } from 'widgets/Media';
import { CalendarIconHandler } from './CalendarIconHandler';
import {
  addPostGetActionTypeForPost,
  addPostGetEditSavedPostObj,
  addPostGetSelectedFBTagList,
  addPostSetCampaignData,
  addPostSetCustomTextEditorData,
  addPostSetInstaPhotoPreviewObj,
  addPostSetLinkData,
  addPostSetSelectedMediaList,
  addPostSetTags,
  addPostUpdateSavedPostDetails,
  calendarDeleteModalOption,
  calendarEditView,
  calendarEnableDatePicker,
  calendarEnableSchedule,
  calendarLocationUpdates,
  calendarSelectDataObject,
  updateScheduledPost
} from 'actions';
import { Paragraph } from 'widgets/Text';
import { SocialMediaLinkPreview } from 'widgets/Preview';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { InfiniteScrollCardList, NoDataFound, alertBoxCall } from 'components';

export const SchedulePosts = ({
  isHubUser,
  postData,
  displayType,
  filterPlatform,
  activePlatforms,
  accountDetails,
  locationActualData,
  postsDetails,
  editId,
  handleOnChangePostContent,
  dispatch
}: calendarContainerTypes.ISchedulePostProps) => {
  const { userOwnership } = useAccountSwitcherData();
  const { calendarRestriction } = useSiteRestriction();

  const availablePlatform = useAvailablePlatforms(PLATFORM_FILTER, userOwnership, null, false).filter((it) => it.value !== 'yelp');

  const isFranchisee = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.is_franchisee || false);
  const overriddenCorporatePostsLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_corporate_posts_locked || false);
  const overriddenCustomPostsLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_custom_posts_locked || false);
  const overriddenCorporatePostsAutoApproved = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_corporate_posts_auto_approved || false);
  const timeZone = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');
  const { savedPostDetails } = useSelector((state: IStore) => state.addPostAccount);
  const accountName = useSelector((state: IStore) => state.accountSwitcher.currentName);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const isScheduleEnabled = useSelector((state: IStore) => state.calendar.isScheduleEnabled);

  const data = isHubUser ? postData : displayType === 'scheduled' ? postData.filter((it) => !it.draft) : displayType === 'awaiting_approval' ? postData.filter((it) => it.draft) : postData;
  const scheduledPosts = availablePlatform.length <= 1 ? data : data?.filter((it) => filterPlatform === 'all' || it.activePlatform === filterPlatform);
  const isLocationUser = userOwnership === USER_OWNERSHIP.ACCOUNT;
  const isHideApprove = isLocationUser ? checkSiteRestriction(USER_OWNERSHIP.ACCOUNT, menuAccess, 'calendar/awaiting_approval') : false;

  // LOCATION DISPATCH EVENT IF THE POST IS CREATED BY THEIR HUB
  const handleShowActivePlatform = (data: calendarContainerTypes.IDataObjType[]) => {
    return data.reduce((acc: calendarContainerTypes.IDataObjType[], curr) => {
      if (curr.isEnabled) {
        if (curr.label === PLATFORM_FILTER[7].label && activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE)) acc = [...acc, curr];
        else if (activePlatforms.includes(curr.label.toLocaleLowerCase())) acc = [...acc, curr];
      }
      return acc;
    }, []);
  };

  const editClickHandler = (data: calendarContainerTypes.ICalendarDataType) => {
    dispatch(calendarSelectDataObject(data));
    dispatch(calendarEditView({ isEditEnabled: false, editId: data.id, editData: data }));
  };

  const handleEditCorporatePost = (data: calendarContainerTypes.ICalendarDataType) => {
    const selectedLocData = locationActualData.find((it) => it.id === data.id);
    if (selectedLocData?.id) {
      editClickHandler(data);
      dispatch(addPostGetActionTypeForPost(AP_ACTION_TYPES.EDIT));
      dispatch(addPostGetEditSavedPostObj(getCorporatePostDetail(activePlatforms, selectedLocData)));
      dispatch(
        addPostUpdateSavedPostDetails(
          getEditOrSchedulePost({
            activePlatforms,
            postDetails: { ...selectedLocData, created_at: data?.scheduledTime || '' },
            accountTzInfoName: accountDetails?.account?.tzinfo_name || '',
            postPlatform: null,
            isCalendarView: true
          })
        )
      );
      dispatch(addPostSetCustomTextEditorData(getPostContentTextField(activePlatforms, selectedLocData)));
      dispatch(addPostGetSelectedFBTagList(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) ? selectedLocData?.page_tag_details?.facebook || [] : []));
      dispatch(addPostSetTags(getUpdatedTagsList('')));
      dispatch(addPostSetSelectedMediaList(getAddMediaListForCreator(selectedLocData, accountDetails?.account?.id || 0)));
      dispatch(addPostSetLinkData(selectedLocData?.link_url ? getCreatorLinkData(selectedLocData) : null));
      if (selectedLocData?.use_instagram && selectedLocData?.photo_urls?.length) {
        dispatch(
          addPostSetInstaPhotoPreviewObj({
            actionType: selectedLocData?.pad_instagram_photos ? CreatorInstaPhotoType.PAD : CreatorInstaPhotoType.CROP,
            photoUrl: { originalUrls: [], previewUrls: [] }
          })
        );
      }
      if (selectedLocData?.campaigns?.length) {
        dispatch(addPostSetCampaignData(selectedLocData?.campaigns));
      }
      dispatch(calendarEditView({ isEditEnabled: true, editId: 0, editData: data }));
    }
  };

  const handleRemoveSchedulePost = (datum: calendarContainerTypes.ICalendarDataType) => {
    dispatch(calendarDeleteModalOption({ isOpenDeleteModal: true, isConfirmDelete: false }));
    dispatch(calendarLocationUpdates({ type: SCHEDULE_PARAM_TYPE.HIDDEN, bool: true, data: datum }));
  };

  const renderScheduledPosts = (datum: calendarContainerTypes.ICalendarDataType, index: number) => {
    const contentText =
      filterPlatform === COMMON_SOCIAL_PROVIDER_TYPE.ALL
        ? // For List Platform changes
          datum.activePlatform
          ? datum?.dataObj?.find((it) => it.isEnabled && it.label === datum.activePlatform)
          : datum?.dataObj?.find((it) => it.isEnabled)
        : // For Filter Platform changes
          datum?.dataObj?.find((it) => it.isEnabled && [filterPlatform, datum.activePlatform].includes(it.label));
    const imageList = getPermittedImgsBasedOnPlatform(datum.activePlatform, datum.activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? datum?.instagramImageUrls || [] : datum?.imageUrls || []);

    const canEditAccountPost = !isHubUser
      ? canEditScheduledPost(datum.isLocked, datum.isCorporatePost, isFranchisee, overriddenCorporatePostsLocked, overriddenCustomPostsLocked, datum.abilities?.can_edit_content)
      : false;
    const canEditAccountDate = !isHubUser ? canEditScheduledPostDateAndTime(datum.isCorporatePost, isFranchisee, overriddenCorporatePostsLocked, datum.abilities?.can_edit_schedule) : false;
    const canApprove = !isHubUser ? canApproveScheduledPost(datum.draft, datum.isCorporatePost, isFranchisee, overriddenCorporatePostsAutoApproved, overriddenCustomPostsLocked) : false;
    return (
      <div className="li-view-main" key={index}>
        <div className="liv-left li-top calb-brief">
          <ImageValidation imgUrl={datum.avatarImg} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={'social-media-post'} customName={'calendar user profile img'} />
          <div className="lvt-details">
            <h3 title={datum.userName}>{datum.userName}</h3>
            {!calendarRestriction.schedule && (
              <div className="lvt-brief">
                <span className="lvt-txt">Scheduled to</span>
                {!isHubUser && (
                  <CalendarIconHandler
                    isBoosted={false} // datum.isBoosted
                    isAwaiting={datum.draft}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="liv-right list-item">
          <div className="header-section">
            <div className="left-section">
              <div className="p-header">
                <div className="p-header-title">
                  <div className="p-avatar">
                    <ImageValidation imgUrl={datum.avatarImg} defaultImg={'user-avatar-common.png'} isNotSvgFormat customClassname={'social-media-post'} customName={'calendar user profile img'} />
                  </div>
                  <div className="p-title">
                    <h5>{accountName}</h5>
                    <h6>
                      {isHubUser
                        ? `@ ${calendarUTCtoLocalHandler(datum.scheduledTime, CALENDAR_DATE_FORMAT)}`
                        : `@${calendarConvertDateBasedOnTimeZone(timeZone, datum.scheduledTime, LOCATION_TIME_ZONE_DATE_TIME_FORMAT, true)}`}
                      {datum.dataObj.find((it) => it.isEnabled && it.label === 'facebook') && datum.isBoosted && <span className="cal-boost-textwithbg">$ Boosted</span>}
                      {datum.campaign && (
                        <div className="campaign-tag">
                          <span>CAMPAIGN</span>
                        </div>
                      )}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-section">
              <div className="fltr-imc selectsocial">
                {handleShowActivePlatform(datum?.dataObj)?.map((it, indexNum: number) => {
                  const validPlatformImg = availablePlatform.find((data) => data.value === it?.label)?.platformImg || '';
                  return validPlatformImg ? (
                    <button
                      type="button"
                      className={`roundedbtn btn btn-link img-siz ${it?.label === datum.activePlatform ? 'active' : ''}`}
                      key={indexNum}
                      onClick={() => dispatch(updateScheduledPost({ ...datum, activePlatform: it?.label }))}
                    >
                      <ImageValidation defaultImg={validPlatformImg} customName={it?.label} />
                    </button>
                  ) : null;
                })}
              </div>
            </div>
          </div>
          <div className="desc-text">
            <Paragraph
              customClassname="post-message"
              facebookTagList={contentText?.tag ? contentText?.tag : []}
              customText={contentText?.personalizedText ? contentText?.personalizedText?.trim() : contentText?.text.trim() || ''}
              actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW}
            />
          </div>
          {imageList?.length && !datum.linkUrl ? (
            <div className="am-media-preview">
              <CarousalImages key={`calendar-list-${datum.id}-${datum.activePlatform}`} imagesList={imageList} showThumbs={false} />
            </div>
          ) : datum?.video ? (
            <div className="am-media-preview pmg">
              {/*pmg*/}
              <div className="video-outer-element">
                <Video videoUrl={datum?.video || ''} light={datum?.video_thumbnail_url} customClassname={'full-width'} />
              </div>
            </div>
          ) : null}
          {datum.linkTitle || datum.linkUrl || datum.linkDescription ? (
            <SocialMediaLinkPreview
              imageUrl={datum.linkPreviewPhoto || imageList[0] || null}
              linkPreviewTitle={datum.linkTitle || ''}
              linkUrl={datum.linkUrl || ''}
              isShowThumbnail={datum.linkPhotos[0] || imageList[0] ? true : false}
              linkPreviewDescription={datum.linkDescription || ''}
              socialMedia={datum.activePlatform}
              isLinkCustomized={datum?.link_preview_customized || false}
              actionType={datum.activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? datum?.facebook_call_to_action_type : datum?.google_call_to_action_type || null}
            />
          ) : null}
          {datum.activePlatform === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE && datum.google_call_to_action_url && datum.google_call_to_action_type && (
            <div className="insta-social-sec-wrp">
              <div className="lefticon">
                {parse(convertURLAsLink(datum.google_call_to_action_url, GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS.find((option) => option.value === datum.google_call_to_action_type)?.label || ''))}
              </div>
            </div>
          )}
          {!isHubUser ? (
            <>
              <div className="header-section cal-mt-btns">
                {!calendarRestriction.schedule && (
                  <div className="left-section">
                    <div
                      className={`remove-btn-cal ${!canEditAccountDate ? 'pointer-events-none' : ''}`}
                      onClick={() => {
                        dispatch(calendarEnableDatePicker({ isOpen: true, dataObj: datum }));
                        dispatch(
                          addPostUpdateSavedPostDetails({
                            ...AP_SAVED_POST_INIT_DETAILS,
                            schedule_date_time: calendarInitScheduledTime(accountDetails?.account?.tzinfo_name || '', datum?.scheduledTime),
                            account_schedule_date_time: dateObjBasedOnTimeZone(accountDetails?.account?.tzinfo_name || '', datum?.start_date)
                          })
                        );
                      }}
                    >
                      <span className="inner-text">
                        <>{calendarConvertDateBasedOnTimeZone(timeZone, datum.scheduledTime, EMAIL_DATE_FORMAT, true)}</>
                      </span>
                      <span className="inner-next-text"> {`(${getTimezoneAbbreviation(timeZone)})`}</span>
                    </div>
                  </div>
                )}
                <div className="right-section r-flx-ac">
                  {!calendarRestriction.delete && (
                    <CustomRippleButton rippleClass={`ripple-unset ac-danger-box calendar-ripple ${!datum.abilities?.can_remove ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.danger}>
                      <button className="ac-btn ac-danger size-sm" onClick={() => handleRemoveSchedulePost(datum)}>
                        Remove
                      </button>
                    </CustomRippleButton>
                  )}
                  {isHideApprove || isLocationUser ? null : (
                    <CustomRippleButton rippleClass={`ripple-unset r-ml2 ac-secondary-box edit-ripple__wrp ${canApprove ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.whiteGrey}>
                      <button
                        className={`ac-btn ac-secondary-white border-0 ac-outline size-sm h-default r-flx r-flx-jc r-flx-ac ${canApprove ? 'disabled' : ''}`}
                        onClick={() => handleOnChangePostContent('draft', true, datum)}
                      >
                        Cancel
                      </button>
                    </CustomRippleButton>
                  )}
                  {!calendarRestriction.edit && (
                    <CustomRippleButton rippleClass={`ripple-unset edit-ripple__wrp r-ml2 ac-secondary-box ${!canEditAccountPost ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.whiteGrey}>
                      <button
                        className={`ac-btn ac-secondary-white border-0 ac-outline size-sm h-default r-flx r-flx-jc r-flx-ac ${!canEditAccountPost ? 'disabled' : ''}`}
                        onClick={() => (datum.isCorporatePost ? handleEditCorporatePost(datum) : editClickHandler(datum))}
                      >
                        {editId === datum.id && (!postsDetails || !savedPostDetails) ? <Spinner animation="border" /> : 'Edit'}
                      </button>
                    </CustomRippleButton>
                  )}
                </div>
              </div>
              {isHideApprove
                ? null
                : !calendarRestriction.approve && (
                    <div className="full-width-btn">
                      <CustomRippleButton rippleClass={`ac-primary-box ${!canApprove ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                        <button
                          className={`ac-btn ac-primary ac-block ${!canApprove ? 'disabled' : ''}`}
                          onClick={() => {
                            if (datum?.activePlatform) {
                              handleOnChangePostContent('draft', false, datum);
                            } else {
                              alertBoxCall(ALERT_BOX_TYPES.ERROR, CONNECT_SOCIAL_MEDIA_TO_APPROVE_THE_POST);
                            }
                          }}
                        >
                          {!canApprove ? 'Approved' : 'Approve'}
                        </button>
                      </CustomRippleButton>
                    </div>
                  )}
            </>
          ) : (
            <>
              {/* --------------------------HUB USER----------------------------- */}
              <div className="header-section cal-mt-btns calendarlist-hubuserfootersec">
                <div className="right-section">
                  {!calendarRestriction.schedule && (
                    <div
                      className="sche-text"
                      onClick={() => {
                        dispatch(calendarEnableSchedule(!isScheduleEnabled));
                        dispatch(calendarSelectDataObject(datum));
                      }}
                    >
                      <ImageValidation isImgValid defaultImg="location-grey" customName="add-img-btn" />
                      Scheduled To <span>{datum.includedAccountsCount}</span>
                    </div>
                  )}
                  <div className="calendar-flr-btn-wrp">
                    {!calendarRestriction.delete && (
                      <CustomRippleButton rippleClass="ac-danger-box" custColor={RIPPLE_COLOR.danger}>
                        <button className="ac-btn ac-danger ac-outline ac-block h-default" onClick={() => handleRemoveSchedulePost(datum)}>
                          Remove
                        </button>
                      </CustomRippleButton>
                    )}
                    {!calendarRestriction.edit && (
                      <CustomRippleButton rippleClass={`ac-secondary-box edit-ripple__wrp r-ml2 edit-ripple__wrp`} custColor={RIPPLE_COLOR.whiteBlue}>
                        <button className="ac-btn ac-secondary-white border-0 ac-outline ac-block h-default r-flx r-flx-jc r-flx-ac border-0" onClick={() => editClickHandler(datum)}>
                          {editId === datum.id && !postsDetails ? <Spinner animation="border" /> : 'Edit'}
                        </button>
                      </CustomRippleButton>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {scheduledPosts.length ? (
        <InfiniteScrollCardList<calendarContainerTypes.ICalendarDataType> data={scheduledPosts} childComp={(data, index) => renderScheduledPosts(data, index)} itemCount={12} />
      ) : (
        <NoDataFound size={NoDataFoundProps.MEDIUM} />
      )}
    </>
  );
};
