import { mgApiTypes, mgReducerTypes } from 'types';
import { AddMediaExpirationValues, CreatorMediaTypeForUser, MG_CLOUD_MEDIA_TYPE, MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES, MG_MEDIA_TYPE, IMAGE_SUPPORTED_FORMAT } from 'utils/constants';

/** Media gallery - Get multiple assets cloudinary response */
export const getMultipleAssetsUploadedInCloud = (
  currentResponse: mgApiTypes.IMGUploadCloudMediaResponse,
  previousCloudData: mgReducerTypes.IMGCloudinaryDataObj[],
  videoCloudinaryId?: string | null
) => {
  let cloudResponseArray: mgReducerTypes.IMGCloudinaryDataObj[] = [...previousCloudData].filter((datum) =>
    videoCloudinaryId ? Boolean(datum?.videoCloudinaryId !== videoCloudinaryId && datum.fileName !== currentResponse.fileName) : datum.imageType ? true : datum.fileName !== currentResponse.fileName
  );
  const resourceType = currentResponse.cloudinaryResponse.resource_type;
  const cloudinaryData = {
    cloudinaryId: currentResponse.cloudinaryResponse.public_id || null,
    s3_md5: currentResponse.cloudinaryResponse.s3_md5 || null,
    fileName: currentResponse.fileName,
    tags: currentResponse.cloudinaryResponse.tags && currentResponse.cloudinaryResponse.tags.join(),
    eTag: currentResponse.cloudinaryResponse.etag,
    secureUrl: currentResponse.cloudinaryResponse.secure_url,
    mediaType: resourceType === MG_CLOUD_MEDIA_TYPE.IMAGE ? MG_MEDIA_TYPE.PHOTO : resourceType === MG_CLOUD_MEDIA_TYPE.RAW ? MG_MEDIA_TYPE.RAW : MG_MEDIA_TYPE.VIDEO,
    ...(currentResponse.cloudinaryResponse.resource_type === MG_CLOUD_MEDIA_TYPE.IMAGE && {
      qualityAnalysis: {
        focus: currentResponse.cloudinaryResponse?.quality_analysis?.focus || 0
      }
    }),
    expiration: AddMediaExpirationValues.NEVER,
    expires_at: null,
    have_mediaRelease: false,
    mgAvailableForUser: CreatorMediaTypeForUser.PERSONAL,
    isImageLockPost: false,
    thumbnail_url: '',
    customVideoThumbnail: MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES.DEFAULT,
    videoCloudinaryId,
    ...(previousCloudData?.[previousCloudData.length - 1]?.imageType && { imageType: previousCloudData?.[previousCloudData.length - 1].imageType })
  };
  cloudResponseArray = [...cloudResponseArray, cloudinaryData];
  return cloudResponseArray;
};

export const mgLikeOrDisLikeStatusUpdate = (dataArray: mgApiTypes.IMGListResponseObj[], likeResponse: mgApiTypes.IMGLikeResponseData) => {
  return dataArray.reduce((acc: mgApiTypes.IMGListResponseObj[], curr) => {
    acc = [...acc, curr.id === likeResponse.assets.id ? { ...curr, liked: likeResponse.assets.liked } : curr];
    return acc;
  }, []);
};

export const mgGetUpdatedAssetsList = (assetsList: mgApiTypes.IMGListResponseObj[], updatedAsset: mgApiTypes.IMGListResponseObj | null) => {
  return (
    updatedAsset &&
    assetsList.reduce((acc: mgApiTypes.IMGListResponseObj[], curr) => {
      if (curr.id === updatedAsset.id) {
        acc = [...acc, updatedAsset];
      } else {
        acc = [...acc, curr];
      }
      return acc;
    }, [])
  );
};

/** Media Gallery - Get the assets filter stats data */
export const getMGFilterStatsData = (
  retainedStats: mgReducerTypes.IMgMediaFilterStatsObj,
  statsData: mgApiTypes.IMgPhotoFilterStatsObj | mgApiTypes.IMgVideoFilterStatsObj | mgApiTypes.IMgDocumentsFilterStatsObj
): mgReducerTypes.IMgMediaFilterStatsObj => {
  return {
    allMediaCount:
      `all_photos_count` in statsData
        ? statsData.all_photos_count || 0
        : `all_videos_count` in statsData
        ? statsData.all_videos_count || 0
        : `all_documents_count` in statsData
        ? statsData.all_documents_count || 0
        : retainedStats.allMediaCount || 0,
    brandMediaCount:
      `brand_photos_count` in statsData
        ? statsData.brand_photos_count || 0
        : `brand_videos_count` in statsData
        ? statsData.brand_videos_count || 0
        : `brand_documents_count` in statsData
        ? statsData.brand_documents_count || 0
        : retainedStats.brandMediaCount || 0,
    locationMediaCount:
      `local_photos_count` in statsData
        ? statsData.local_photos_count || 0
        : `local_videos_count` in statsData
        ? statsData.local_videos_count || 0
        : `local_documents_count` in statsData
        ? statsData.local_documents_count || 0
        : retainedStats.locationMediaCount || 0,
    contentSupplierCount:
      `cs_syndicated_photos_count` in statsData
        ? statsData.cs_syndicated_photos_count || 0
        : `cs_syndicated_videos_count` in statsData
        ? statsData.cs_syndicated_videos_count || 0
        : `cs_syndicated_documents_count` in statsData
        ? statsData.cs_syndicated_documents_count || 0
        : retainedStats.contentSupplierCount || 0,
    newlyAddedMediaCount:
      `newly_added_photos_count` in statsData
        ? statsData.newly_added_photos_count || 0
        : `newly_added_videos_count` in statsData
        ? statsData.newly_added_videos_count || 0
        : retainedStats.newlyAddedMediaCount || 0,
    unusedMediaCount:
      `unused_photos_count` in statsData ? statsData.unused_photos_count || 0 : `unused_videos_count` in statsData ? statsData.unused_videos_count || 0 : retainedStats.unusedMediaCount || 0,
    usedMediaCount: `used_photos_count` in statsData ? statsData.used_photos_count || 0 : `used_videos_count` in statsData ? statsData.used_videos_count || 0 : retainedStats.usedMediaCount || 0
  };
};

export const getCloudinaryAssetInitialResponse = (fileName: string, imageType?: string) => {
  const mediaType = IMAGE_SUPPORTED_FORMAT?.includes(`.${fileName.split('.').pop()}` || '') ? MG_MEDIA_TYPE.PHOTO : '';
  return {
    cloudinaryId: null,
    fileName,
    tags: null,
    eTag: null,
    secureUrl: null,
    mediaType,
    qualityAnalysis: {
      focus: 0
    },
    expiration: AddMediaExpirationValues.NEVER,
    expires_at: null,
    have_mediaRelease: false,
    mgAvailableForUser: CreatorMediaTypeForUser.PERSONAL,
    isImageLockPost: false,
    thumbnail_url: '',
    customVideoThumbnail: MG_CUSTOMIZE_VIDEO_THUMBNAIL_VALUES.DEFAULT,
    imageType
  };
};
