import { useCallback, useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Button from '@atlaskit/button';
// import { Button } from 'react-bootstrap';
import useOnClickOutside from 'use-onclickoutside';

import { ImageValidation } from 'widgets/Media';
import {
  accountSwitcherRequest,
  accountProfileReset,
  getFranchisorDetailsResponse,
  settingsUpdateRallioProfile,
  setDLStoreDetails,
  setIsDataLoading,
  franchisorProfileReset,
  franchisorError,
  accountError,
  // aiGetSubscriptionPlanRequest,
  updateRevvState,
  hashTagsResetAll,
  aiEngineReset
} from 'actions';
import { USER_OWNERSHIP, SIDE_NAV_DROPDOWN_DATA, CREATOR_SAVE_CHANGES_IN_HEADER_DD_OBJ, FRANCHISORS_EXCEPTION_ERROR_MSG, ACCOUNT_ERROR_MSG, SIDE_NAV_NAME } from 'utils/constants';
import { commonAccountSwitcher, IStore } from 'types';
import { CustomDropdown } from 'widgets/CustomDropdown';
import { CommonSaveChangesConfirmModal } from 'components';
import { getInitilalRallioProfileDetails, getInitialStoreDetails, isValueHasExponentialTerm } from 'utils/helpers';
import { shutdownIntercomBot } from 'utils/helpers/external-script';
import { useAccountSwitcherData, useBackToTopButton, useNavBarData } from 'utils/hooks';
// import { logout } from 'revv/actions';

/** DROPDOWN OPTION WITH CUSTOMIZE STYLE */
const CustomOption = (props: commonAccountSwitcher.ICustomOptionProps | any) => {
  const { data, innerRef, innerProps } = props;
  return data.custom && data.type === SIDE_NAV_DROPDOWN_DATA.ADMIN ? (
    <div ref={innerRef} {...innerProps} className="as-item admin-icon-wraps">
      {data.name}
    </div>
  ) : (
    <components.Option {...props} className="as-item location-icon-wraps" />
  );
};

export const DropDownComponent = () => {
  // const dropDownData: commonAccountSwitcher.IDropdownOptionData[] = [];
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { id, userOwnership, selectedOption, dropDownData, currentRoute } = useAccountSwitcherData();
  const { subNavPageName } = useNavBarData();
  // const location = useLocation();
  const navigate = useNavigate();
  useBackToTopButton('scrollTarget', 'backToTopBtn');

  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin) || false;
  const isContentWriter = useSelector((state: IStore) => state.login.activeUser?.content_writer) || false;
  const isFranchisor = useSelector((state: IStore) => state.login.activeUser?.franchisor) || false;
  const { franchisors, accounts } = useSelector((state: IStore) => state.accountSwitcher);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const contentModified = useSelector((state: IStore) => state.common.contentModified);
  const currentAccountOrFranchisor = useSelector((state: IStore) => state.accountSwitcher.currentAccountOrFranchisor);
  const currentUserOwnership = useSelector((state: IStore) => state.accountSwitcher.userOwnership);
  const accountSwitcherError = useSelector((state: IStore) => state.accountSwitcher.error);
  const currentName = useSelector((state: IStore) => state.accountSwitcher.currentName);
  const isDataLoading = useSelector((state: IStore) => state.common.isDataLoading);
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const rallioBrandId = useSelector((state: IStore) => state.revv.login.activeClientDetail?.rallioBrandId);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const accountSwitcherId = useSelector((state: IStore) => state.accountSwitcher.id);

  const [dropdownState, setDropDownState] = useState<commonAccountSwitcher.IDropdownState>({ isOpen: false });
  const [saveChangesObj, setSaveChangesObj] = useState(CREATOR_SAVE_CHANGES_IN_HEADER_DD_OBJ);

  const dropdownRef = useRef<any>(null);
  useOnClickOutside(dropdownRef, () => dropdownState.isOpen && setDropDownState({ isOpen: false }));

  // Revv
  useEffect(() => {
    if (topLevelFranchisorId && id && rallioBrandId) {
      const isRevvEnabled = id === Number(rallioBrandId) || topLevelFranchisorId === Number(rallioBrandId);
      dispatch(updateRevvState(isRevvEnabled));
    }
  }, [id, topLevelFranchisorId, rallioBrandId, dispatch]);

  /** UPDATE RALLIO PROFILE INITIAL VALUE ONCE ACCOUNTS API CALL DONE */
  useEffect(() => {
    if (accountDetails) {
      const initialRallioProfileDetails = getInitilalRallioProfileDetails(accountDetails.account);
      dispatch(settingsUpdateRallioProfile(initialRallioProfileDetails));
      const initialStoreDetails = getInitialStoreDetails(accountDetails.account);
      dispatch(setDLStoreDetails(initialStoreDetails));
    }
  }, [accountDetails, dispatch]);

  useEffect(() => {
    const isValidAccount = typeof id === 'number' && id;
    const isValidId = currentAccountOrFranchisor ? currentAccountOrFranchisor?.id !== id : true;
    const isValidOwnership = currentUserOwnership ? currentUserOwnership !== userOwnership : true;
    // If the account Id is valid, get their details
    if (!isDataLoading && isValidAccount && (isValidId || isValidOwnership) && !accountSwitcherError) {
      // if (isValueHasExponentialTerm(id)) {
      //   dispatch(setIsDataLoading(true));
      // } else {
      handleConfirmedAccChange({}, id, userOwnership);

      // }
    }
    // If the account Id is invalid, show warning modal
    if (!id && [USER_OWNERSHIP.FRANCHISOR, USER_OWNERSHIP.ACCOUNT].includes(userOwnership)) {
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR) dispatch(franchisorError(FRANCHISORS_EXCEPTION_ERROR_MSG));
      else dispatch(accountError(ACCOUNT_ERROR_MSG));
    }
  }, [isDataLoading, id, userOwnership, currentUserOwnership, currentAccountOrFranchisor, accountSwitcherError, topLevelFranchisorId]); // eslint-disable-line

  const handleDropdownChange = (target: commonAccountSwitcher.IAccountSwitcherRequest | void | any) => {
    setDropDownState((state: commonAccountSwitcher.IDropdownState) => ({ ...state, isOpen: false }));
    // dispatch(logout());
    // Check whether content changes to be discarded
    if (contentModified && (target.name !== currentName || target.value !== id)) {
      setSaveChangesObj({ isShow: true, targetObj: target });
    } else {
      handleRouteChangesBasedOnAccount(target);
    }
    shutdownIntercomBot();
    dispatch(aiEngineReset());
    localStorage.removeItem('lastRunDate');
  };

  const handleRouteChangesBasedOnAccount = (target: commonAccountSwitcher.IAccountSwitcherRequest | void | any) => {
    const selectedUserOwnership = target.type === SIDE_NAV_DROPDOWN_DATA.ADMIN ? USER_OWNERSHIP.FRANCHISOR : USER_OWNERSHIP.ACCOUNT;
    let route = currentRoute;
    if (subNavPageName === SIDE_NAV_NAME.CONTENT_MEDIA) {
      route = 'content/media/photos';
    } else if (subNavPageName === SIDE_NAV_NAME.CONTENT_CALENDAR) {
      route = selectedUserOwnership === USER_OWNERSHIP.FRANCHISOR ? 'content/calendar/scheduled' : 'content/calendar';
    } else if (subNavPageName === SIDE_NAV_NAME.DL_PROFILE) {
      route = 'directory_listings/profile/store_details';
    }
    navigate(`/${selectedUserOwnership}/${target.value}/${route}`);
  };

  const handleConfirmedAccChange = (target: commonAccountSwitcher.IAccountSwitcherRequest | void | any, id?: number, userOwnership?: string) => {
    dispatch(setIsDataLoading(true));
    dispatch(hashTagsResetAll());
    if ((target && target.type === SIDE_NAV_DROPDOWN_DATA.ADMIN) || userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      target.userOwnership = USER_OWNERSHIP.FRANCHISOR;
      target.id = +target.value || id;
      // Reset ai engine card details while switching account
      if (id !== accountSwitcherId) {
        localStorage.removeItem('lastRunDate');
      }
      dispatch(accountProfileReset());
      dispatch(franchisorProfileReset());
      dispatch(getFranchisorDetailsResponse(null));
      dispatch(accountSwitcherRequest({ ...target, userId: activeUserId }));
      // get ai subscription details
      // dispatch(aiGetSubscriptionPlanRequest({ id, topLevelFranchisorId, userOwnership, userId: activeUserId }));
    } else if ((target && target.type !== SIDE_NAV_DROPDOWN_DATA.ADMIN) || userOwnership === USER_OWNERSHIP.ACCOUNT) {
      target.userOwnership = USER_OWNERSHIP.ACCOUNT;
      target.id = +target.value || id;
      dispatch(accountProfileReset());
      dispatch(franchisorProfileReset());
      dispatch(getFranchisorDetailsResponse(null));
      dispatch(accountSwitcherRequest({ ...target, userId: activeUserId }));
      // get ai subscription details
      // dispatch(aiGetSubscriptionPlanRequest());
    }
  };

  /** Navigate to their corresponding parent account */
  const handleRedirectToParent = () => {
    if (franchisors.length && id !== franchisors[0].id) {
      handleDropdownChange({
        label: franchisors[0].name,
        name: franchisors[0].name,
        value: franchisors[0].id,
        type: SIDE_NAV_DROPDOWN_DATA.ADMIN,
        custom: true
      });
    }
  };

  /** CUSTOM STYLE PROVIDED TO SEARCH & DROPDOWN */
  const selectStyles = {
    control: (provided: any) => ({ ...provided, minWidth: 240, margin: 8 }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' })
  };

  /** DROPDOWN OPTIONS WITH CUSTOMIZED STYLE */
  const Menu = (props: commonAccountSwitcher.IMenuProps) => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
      <>
        <div className={`accountSwitcherMain ${dropdownState.isOpen ? 'active' : ''}`}>
          <div
            className="ds-menu"
            css={{
              backgroundColor: 'white',
              borderRadius: 4,
              boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
              marginTop: 8,
              position: 'absolute',
              zIndex: 2
            }}
            {...props}
          />
          {(isAdmin || isContentWriter || isFranchisor) && (
            <div className="home-action">
              <ImageValidation isImgValid defaultImg="home-blue" onClick={() => handleRedirectToParent()} customName={'Home'} />
            </div>
          )}
        </div>
      </>
    );
  };

  /** PLACE FIXED POSITION OF DROPDOWN ON SCREEN FIT */
  const Blanket = (props: commonAccountSwitcher.IBlanketProps) => (
    <div
      className="ds-blanket"
      css={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1
      }}
      {...props}
    />
  );

  /** PARENT DROPDOWN COMPONENT */
  const Dropdown = ({ children, isOpen, target, onClose }: any) => (
    <div className="ds-dropdown" title={selectedOption.label} css={{ position: 'relative' }} ref={dropdownRef}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );

  const Svg = (p: commonAccountSwitcher.ISvgProps) => <svg width="22" height="22" cursor="pointer" viewBox="0 0 27 27" focusable="false" role="presentation" {...p} />;

  /** SEARCH ICON IN SEARCH BAR */
  const DropdownIndicator = () => (
    <div className="ds-drp-indicator" css={{ color: '#F08080', height: 20, width: 20 }}>
      <Svg>
        <path d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z" fill="currentColor" fillRule="evenodd" />
      </Svg>
    </div>
  );

  // /** DROPDOWN ICON SVG */
  // const ChevronDown = () => (
  //   <Svg style={{ marginRight: -6 }}>
  //     <path
  //       d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
  //       fill="currentColor"
  //       fillRule="evenodd"
  //     />
  //   </Svg>
  // );

  return (
    <>
      {dropdownState.isOpen ? <div className="user-dropdown__backdrop modal-backdrop show" /> : null}
      {isValueHasExponentialTerm(id) ? null : dropDownData.length ? (
        <div
          className={`brand-hub--active ha-item ${selectedOption.type === SIDE_NAV_DROPDOWN_DATA.ADMIN ? 'admin-user' : 'local-user'} ${
            accounts.length + franchisors.length > 1 ? '' : 'events-none'
          } ${isDataLoading ? 'events-none' : ''}`}
        >
          <Dropdown
            isOpen={dropdownState.isOpen}
            onClose={() => setDropDownState((state: commonAccountSwitcher.IDropdownState) => ({ ...state, isOpen: !dropdownState.isOpen }))}
            target={
              <Button
                className="h-btn"
                // iconAfter={(accounts && franchisors && accounts.length + franchisors.length) > 1 ? <ChevronDown /> : ''}
                onClick={() => {
                  if (accounts && franchisors && accounts.length + franchisors.length > 1) {
                    setDropDownState((state: commonAccountSwitcher.IDropdownState) => ({ ...state, isOpen: !dropdownState.isOpen }));
                  }
                }}
                // isSelected={dropdownState.isOpen}
              >
                {selectedOption.value ? `${selectedOption.label}` : 'Select a hub or location to view'}
              </Button>
            }
            rootClose
          >
            <CustomDropdown
              classNamePrefix="rs-drp"
              components={{ Option: CustomOption, DropdownIndicator, IndicatorSeparator: null }}
              id="account-switcher-dropdown"
              placeholder="Select a hub or location to view"
              onChange={handleDropdownChange}
              styles={selectStyles}
              value={selectedOption}
              options={dropDownData}
              isSearchable={dropDownData.length === 1 ? false : true}
              menuIsOpen
              autoFocus
            />
          </Dropdown>
        </div>
      ) : null}
      <CommonSaveChangesConfirmModal
        isModalShow={saveChangesObj.isShow}
        handleModalClose={() => setSaveChangesObj(CREATOR_SAVE_CHANGES_IN_HEADER_DD_OBJ)}
        handleNavigation={() => {
          if (+saveChangesObj.targetObj.value) {
            handleRouteChangesBasedOnAccount(saveChangesObj.targetObj);
          }
        }}
      />
    </>
  );
};
