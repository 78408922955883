import { takeLatest, put, takeEvery } from 'redux-saga/effects';

import {
  dashboardSurveyAnswerFailure,
  dashboardSurveyAnswerResponse,
  dashboardSurveyQuestionFailure,
  dashboardSurveyQuestionResponse,
  dashboardSurveyRecentCommentsFailure,
  dashboardSurveyRecentCommentsResponse,
  dashboardSurveyStatsFailure,
  dashboardSurveyStatsResponse,
  dashboardSurveyNotificationFailure,
  dashboardSurveyNotificationResponse,
  dashboardSurveyResolvedNotificationFailure,
  dashboardSurveyResolvedNotificationResponse,
  dashboardResolveNotificationFailure,
  dashboardSurveyNotificationRequest,
  dashboardResolveNotificationResponse,
  dashboardLocationDetailedViewFailure,
  dashboardLocationDetailedViewResponse,
  dashboardSurveySendMessageFailure,
  dashboardSurveySendMessageResponse,
  dashboardReviewStatsRequestFailure,
  dashboardReviewStatsResponse
} from 'revv/actions';
import { API_TYPES } from 'utils/constants';
import { apiCall, defaultRevvHeader, defaultHeader } from 'utils/helpers';
import * as actionTypes from 'revv/actions/action-types';
import {
  ISagaPayload,
  IDashboardSurveyStatsRequestParams,
  SagaYield,
  SagaValue,
  IDashboardSurveyStatsResponse,
  IDashboardSurveyRecentCommentsRequestParams,
  IDashboardSurveyRecentComments,
  IDashboardSurveyRecentCommentsResponse,
  IDashboardSurveyAnswerParams,
  IDashboardSurveyNotificationsRequestParams,
  IDashboardSurveyNotificationListResponse,
  IDashboardSurveyNotificationList,
  IDashboardSurveyQuestionList,
  IDashboardSurveyResolvedNotificationsResponse,
  IDashboardSurveyNotificationsResponse,
  IDashboardQuestionReqParams,
  IDashboardSurveySendMessageRequestParams,
  IDashboardSurveySendMessageResponse,
  IDashboardReviewStatsRequestParams
} from 'revv/types';
import { API_ROUTES, calculateQuaterDifferenceInPercentage, surveyAnswerModifier, surveyAnswerModifierForNPS } from 'revv/utils';

function* getDashboardSurveyStatsRequest(action: ISagaPayload<IDashboardSurveyStatsRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: action.payload?.fromPage === 'dashboard' ? API_ROUTES.dashboardSurveyStats.apiPath : API_ROUTES.surveyStats.apiPath,
      action: action.payload?.fromPage === 'dashboard' ? API_ROUTES.dashboardSurveyStats.method : API_ROUTES.surveyStats.method, // action is instead of api-method
      type: API_TYPES.REVV,
      headers: defaultRevvHeader(),
      data: {
        // client_id: action.payload?.clientId || '',
        from: action.payload?.from,
        from_prev: action.payload?.fromPrev,
        to: action.payload?.to,
        to_prev: action.payload?.toPrev,
        location_ids: action.payload?.locationId,
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.rallioFranchisorId
      }
    });
    if (response.status !== 200) {
      yield put(dashboardSurveyStatsFailure(response.data.error || 'Error'));
    } else {
      const DashboardSurveyStatsResponseData: IDashboardSurveyStatsResponse = {
        sentCount: response.data.data.sent_count,
        completedCount: response.data.data.completed_count,
        averageRating: response.data.data.average_rating,
        npsScore: response.data.data.nps_score,
        agreedToReviewCount: response.data.data.agreed_to_review_count,
        totalVisitCount: response.data.data.total_visits_count,
        surveyAverageRating: response.data.data.average_rating,
        surveyFeedBackCount: response.data.data.left_feedback_count
      };
      yield put(dashboardSurveyStatsResponse(DashboardSurveyStatsResponseData));
    }
  } catch (error) {
    yield put(dashboardSurveyStatsFailure('Error Occured'));
  }
}

function* getDashboardReviewStatsRequest(action: ISagaPayload<IDashboardReviewStatsRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.rallioReviewStats.apiPath,
      action: API_ROUTES.rallioReviewStats.method,
      headers: defaultHeader(),
      params: {
        date_range: action.payload?.dateRange,
        account_id: action.payload?.ids
      }
    });
    if (response.status !== 200) {
      yield put(dashboardReviewStatsRequestFailure(response.data.error || 'Error'));
    }
    yield put(dashboardReviewStatsResponse(response.data));
  } catch (error) {
    yield put(dashboardReviewStatsRequestFailure('Error Occured'));
  }
}
function* getDashboardRecentCommentsRequest(action: ISagaPayload<IDashboardSurveyRecentCommentsRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.dashboardRecentComments.apiPath,
      action: API_ROUTES.dashboardRecentComments.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      data: {
        client_id: action.payload?.clientId,
        location_id: action.payload?.locationId,
        from: action.payload?.from,
        to: action.payload?.to,
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.rallioFranchisorId
      }
    });
    if (response.status !== 200) {
      yield put(dashboardSurveyRecentCommentsFailure(response.data.errors[0].detail || 'Error'));
    } else {
      if (response.data) {
        const dashboardSurveyRecentCommentsResponseData: IDashboardSurveyRecentComments[] =
          response.data?.data && response.data?.data?.length > 0
            ? response.data.data.map((it: IDashboardSurveyRecentCommentsResponse) => {
                return {
                  id: it.id,
                  surveyId: it.survey_id,
                  locationId: it.location_id || 0,
                  locationName: it?.location_name || '',
                  comment: it?.comment || '',
                  secondaryComment: it?.secondary_comment || '',
                  surveyCreatedAt: it?.survey_created_at || '',
                  surveyCompletedAt: it?.survey_completed_at || ''
                };
              })
            : [];
        yield put(dashboardSurveyRecentCommentsResponse(dashboardSurveyRecentCommentsResponseData));
      }
    }
  } catch (error) {
    yield put(dashboardSurveyRecentCommentsFailure('Error Occured'));
  }
}

function* getDashboardSurveySendMessageRequest(action: ISagaPayload<IDashboardSurveySendMessageRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.dashboardSurveySendMessage.apiPath,
      action: API_ROUTES.dashboardSurveySendMessage.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      data: {
        location_id: action.payload?.locationId,
        client_id: action.payload?.clientId,
        message: action.payload?.message,
        survey_id: action.payload?.surveyId
      }
    });
    if (response.status !== 200) {
      yield put(dashboardSurveySendMessageFailure(response?.data?.error || 'Error Occured'));
      if (action.payload?.callback) {
        action.payload?.callback();
      }
    } else {
      const dashboardSurveySendMessageData: IDashboardSurveySendMessageResponse = {
        error: response.data.data.error,
        message: response.data.data.message
      };
      if (action.payload?.callback) {
        action.payload?.callback();
      }
      yield put(dashboardSurveySendMessageResponse(dashboardSurveySendMessageData));
    }
  } catch (error: any) {
    if (action.payload?.callback) {
      action.payload?.callback();
    }
    yield put(dashboardSurveySendMessageFailure('Error Occured'));
  }
}

function* getDashboardSurveyQuestions(action: ISagaPayload<IDashboardQuestionReqParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.dashboardSurveyQuestion.apiPath,
      action: API_ROUTES.dashboardSurveyQuestion.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      params: {
        client_id: action.payload?.clientId,
        type: action.payload?.type
      }
    });

    if (response.status !== 200) {
      yield put(dashboardSurveyQuestionFailure('Error Occured'));
    } else {
      yield put(dashboardSurveyQuestionResponse(response.data.data));
    }
  } catch (error) {
    yield put(dashboardSurveyQuestionFailure('Error Occured'));
  }
}

function* getDashboardSurveyAnswerRequest(action: ISagaPayload<IDashboardSurveyAnswerParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.dashboardSurveyAnswer.apiPath,
      action: API_ROUTES.dashboardSurveyAnswer.method,
      headers: defaultRevvHeader(),
      type: API_TYPES.REVV,
      data: {
        client_id: action.payload?.clientId || '',
        from: action.payload?.from,
        from_prev: action.payload?.fromPrev,
        to: action.payload?.to,
        to_prev: action.payload?.toPrev,
        location_id: action.payload?.locationId,
        hub_type_id: action.payload?.hubTypeId,
        type: action.payload?.type,
        day_wise: action.payload?.isDayWise,
        question_id: action.payload?.questionId,
        dates: action.payload?.dateList,
        hourly: action.payload?.isHourly,
        rallio_account_ids: action.payload?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.rallioFranchisorId
      }
    });

    if (response.status !== 200) {
      if (!!action.payload?.isDayWise) {
        yield put(dashboardSurveyAnswerFailure({ focusAreaDayWise: 'Error Occured' }));
      } else if (action.payload?.isHourly) {
        yield put(dashboardSurveyAnswerFailure({ focusAreaHourly: 'Error Occured' }));
      } else if (action.payload?.isSurveyAnsOnly || action.payload?.hubTypeId !== undefined) {
        if (action.payload?.hubTypeId !== undefined) {
          yield put(dashboardSurveyAnswerFailure({ surveyTable: 'Error Occured' }));
        } else if (action.payload?.isSurveyAnsOnly) {
          yield put(dashboardSurveyAnswerFailure({ surveyAnswer: 'Error Occured' }));
        }
      }
    } else {
      if (!!action.payload?.isDayWise) {
        yield put(dashboardSurveyAnswerResponse({ focusAreaDayWise: response.data.data }));
      } else if (action.payload?.isHourly) {
        yield put(dashboardSurveyAnswerResponse({ focusAreaHourly: response.data.data }));
      } else if (action.payload?.isSurveyAnsOnly || action.payload?.hubTypeId !== undefined) {
        const currentSurveyAns = action.payload.npsSurveyType
          ? surveyAnswerModifierForNPS(response.data.data.current, action.payload?.activeClientName || '')
          : surveyAnswerModifier(response.data.data.current, action.payload?.activeClientName || '');
        const prevSurveyAns = action.payload.npsSurveyType
          ? surveyAnswerModifierForNPS(response.data.data.prev, action.payload?.activeClientName || '')
          : surveyAnswerModifier(response.data.data.prev, action.payload?.activeClientName || '');

        if (action.payload?.hubTypeId !== undefined) {
          yield put(dashboardSurveyAnswerResponse({ surveyTable: { currentSurveyAns, prevSurveyAns } }));
        }
        currentSurveyAns.forEach((question) => {
          const prevQuestion = prevSurveyAns.find((q) => q.id === question.id);
          if (question.isMultipleQuestion) {
            if (question?.multiQuestionData?.length) {
              const setObj: { [key: string]: any } = {};
              const data = [...question.multiQuestionData]
                ?.map((it) => {
                  const prevMultiQuestion = prevQuestion?.multiQuestionData?.find((datum) => datum.answer === it.answer);
                  const scoreComparision = calculateQuaterDifferenceInPercentage(it.scorePercentage, prevMultiQuestion ? prevMultiQuestion?.scorePercentage : 0);
                  return {
                    ...it,
                    lastPeriod: scoreComparision.difference,
                    lastPeriodCmp: scoreComparision.differenceInPercentage
                  };
                })
                .reduce((acc: any, item) => {
                  if (!setObj[`${item.answer}`]) {
                    setObj[`${item.answer}`] = item;
                    acc.push(item);
                  }
                  return acc;
                }, []);
              question.multiQuestionData = data;
            }
          } else {
            const scoreComparision = calculateQuaterDifferenceInPercentage(question.scorePercentage || 0, prevQuestion ? prevQuestion.scorePercentage || 0 : 0);
            question.lastPeriod = scoreComparision.difference;
            question.lastPeriodCmp = scoreComparision.differenceInPercentage;
          }
        });
        if (action.payload?.isSurveyAnsOnly) {
          yield put(dashboardSurveyAnswerResponse({ surveyAnswer: { currentSurveyAns, prevSurveyAns } }));
        }
      }
    }
  } catch (error) {
    if (!!action.payload?.isDayWise) {
      yield put(dashboardSurveyAnswerFailure({ focusAreaDayWise: 'Error Occured' }));
    } else if (action.payload?.isHourly) {
      yield put(dashboardSurveyAnswerFailure({ focusAreaHourly: 'Error Occured' }));
    } else if (action.payload?.isSurveyAnsOnly || action.payload?.hubTypeId !== undefined) {
      if (action.payload?.hubTypeId !== undefined) {
        yield put(dashboardSurveyAnswerFailure({ surveyTable: 'Error Occured' }));
      } else if (action.payload?.isSurveyAnsOnly) {
        yield put(dashboardSurveyAnswerFailure({ surveyAnswer: 'Error Occured' }));
      }
    }
  }
}

function* getDashboardSurveyNotificationsRequest(action: ISagaPayload<IDashboardSurveyNotificationsRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.dashboardSurveyNotifications.apiPath,
      action: API_ROUTES.dashboardSurveyNotifications.method,
      type: API_TYPES.REVV,
      headers: defaultRevvHeader(),
      data: {
        client_id: action.payload?.params?.clientId,
        from: action.payload?.params?.from,
        to: action.payload?.params?.to,
        from_prev: action.payload?.params?.fromPrev,
        to_prev: action.payload?.params?.toPrev,
        location_id: action.payload?.params?.locationId,
        offset: action.payload?.params?.offset,
        limit: action.payload?.params?.limit,
        isStopLoading: action.payload?.params?.isStopLoading,
        rallio_account_ids: action.payload?.params?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.params?.rallioFranchisorId
      }
    });
    if (response.status !== 200) {
      yield put(dashboardSurveyNotificationFailure(response.data.error));
    } else {
      if (response.data) {
        const surveyNotificationsListData: IDashboardSurveyNotificationListResponse[] =
          response.data?.data.notification_list && response.data?.data.notification_list?.length > 0
            ? response.data.data.notification_list.map((it: IDashboardSurveyNotificationList) => {
                return {
                  amount: it?.amount || 0,
                  comments: it?.comments || '',
                  customerName: it?.customer_name || '',
                  datetime: it?.datetime || '-',
                  emailAddr: it?.email_addr || '',
                  locationName: it?.location_name || '',
                  mobNumber: it?.mob_number || '',
                  overallExp: it?.overall_exp || 0,
                  customerId: it.customer_id,
                  ppcCard: it?.ppc_card || '',
                  questions:
                    it?.questions?.length > 0
                      ? it.questions.map((surQueslist: IDashboardSurveyQuestionList) => {
                          return {
                            question: surQueslist?.question || '',
                            answer: surQueslist?.answer || ''
                          };
                        })
                      : [],
                  receipt: it?.receipt || '',
                  register: it?.register || '',
                  retailType: it?.retail_type || '',
                  staffId: it?.staff_id || '',
                  store: it?.store || '',
                  surveyId: it?.survey_id || 0,
                  resolvedAt: it?.resolved_at || '',
                  resolvedComment: it?.resolved_comment || ''
                };
              })
            : [];
        const dashboardSurveyNotificationResponseData: IDashboardSurveyNotificationsResponse = {
          notificationList: surveyNotificationsListData,
          totalCount: response.data.data.total_count,
          escalationCount: response.data.data.escalation_count,
          averageResponseTime: response.data.data.average_response_time,
          unresolvedCount: response.data.data.unresolved_count,
          allTimeUnresolvedCount: response.data.data.alltime_unresolved_count,
          resolvedCount: response.data.data.resolved_count
        };
        yield put(dashboardSurveyNotificationResponse(dashboardSurveyNotificationResponseData));
      }
    }
  } catch (error: any) {
    yield put(dashboardSurveyNotificationFailure('Error Occured'));
  }
}

function* getDashboardSurveyResolvedNotificationsRequest(action: ISagaPayload<IDashboardSurveyNotificationsRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.dashboardSurveyResolvedNotifications.apiPath,
      action: API_ROUTES.dashboardSurveyResolvedNotifications.method,
      type: API_TYPES.REVV,
      headers: defaultRevvHeader(),
      data: {
        client_id: action.payload?.params?.clientId,
        from: action.payload?.params?.from,
        to: action.payload?.params?.to,
        from_prev: action.payload?.params?.fromPrev,
        to_prev: action.payload?.params?.toPrev,
        location_id: action.payload?.params?.locationId,
        offset: action.payload?.params?.offset,
        limit: action.payload?.params?.limit,
        isStopLoading: action.payload?.params?.isStopLoading,
        rallio_account_ids: action.payload?.params?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.params?.rallioFranchisorId
      }
    });
    if (response.status !== 200) {
      yield put(dashboardSurveyResolvedNotificationFailure(response.data.error));
    } else {
      if (response.data) {
        const surveyResolvedNotificationsListData: IDashboardSurveyNotificationListResponse[] =
          response.data?.data && response.data?.data?.length > 0
            ? response.data.data.map((it: IDashboardSurveyNotificationList) => {
                return {
                  amount: it?.amount || 0,
                  comments: it?.comments || '',
                  customerName: it?.customer_name || '',
                  datetime: it?.datetime || '-',
                  emailAddr: it?.email_addr || '',
                  locationName: it?.location_name || '',
                  mobNumber: it?.mob_number || '',
                  overallExp: it?.overall_exp || 0,
                  ppcCard: it?.ppc_card || '',
                  customerId: it?.customer_id,
                  questions:
                    it?.questions?.length > 0
                      ? it.questions.map((surQueslist: IDashboardSurveyQuestionList) => {
                          return {
                            question: surQueslist?.question || '',
                            answer: surQueslist?.answer || ''
                          };
                        })
                      : [],
                  receipt: it?.receipt || '',
                  register: it?.register || '',
                  retailType: it?.retail_type || '',
                  staffId: it?.staff_id || '',
                  store: it?.store || '',
                  surveyId: it?.survey_id || 0,
                  resolvedAt: it?.resolved_at || '',
                  resolvedComment: it?.resolved_comment || ''
                };
              })
            : [];
        const dashboardSurveyResolvedNotificationResponseData: IDashboardSurveyResolvedNotificationsResponse = {
          resolvedNotificationList: surveyResolvedNotificationsListData
        };
        yield put(dashboardSurveyResolvedNotificationResponse(dashboardSurveyResolvedNotificationResponseData));
      }
    }
  } catch (error: any) {
    yield put(dashboardSurveyResolvedNotificationFailure('Error Occured'));
  }
}

function* getDashboardResolvedNotificationsRequest(action: ISagaPayload<IDashboardSurveyNotificationsRequestParams>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.dashboardResolveNotifications.apiPath,
      action: API_ROUTES.dashboardResolveNotifications.method,
      type: API_TYPES.REVV,
      headers: defaultRevvHeader(),
      data: {
        client_id: action.payload?.params?.clientId,
        from: action.payload?.params?.from,
        to: action.payload?.params?.to,
        from_prev: action.payload?.params?.fromPrev,
        to_prev: action.payload?.params?.toPrev,
        location_id: action.payload?.params?.locationId,
        offset: action.payload?.params?.offset,
        limit: action.payload?.params?.limit,
        survey_id: action.payload?.params?.surveyId,
        resolved_comment: action.payload?.params?.resolvedComment,
        rallio_account_ids: action.payload?.params?.rallioLocationId,
        rallio_franchisor_ids: action.payload?.params?.rallioFranchisorId
      }
    });
    if (response.status !== 200) {
      yield put(dashboardResolveNotificationFailure(response.data.error));
    } else {
      if (action.payload?.params) {
        yield put(dashboardSurveyNotificationRequest({ params: { ...action.payload?.params, isStopLoading: false } }));
      }
      if (response.data) {
        if (action.payload?.callback) {
          action.payload.callback(response.data?.data?.message);
        }
        yield put(dashboardResolveNotificationResponse());
      }
    }
  } catch (error: any) {
    yield put(dashboardResolveNotificationFailure('Error Occured'));
  }
}

function* getDashboardLocationDetails(action: ISagaPayload<number>): Generator<SagaYield, void, SagaValue> {
  try {
    const response = yield apiCall({
      apiPath: API_ROUTES.surveyDetail.apiPath.replace(':id', `${action.payload}`),
      action: API_ROUTES.surveyDetail.method,
      type: API_TYPES.REVV,
      headers: defaultRevvHeader()
    });

    if (response.status !== 200) {
      yield put(dashboardLocationDetailedViewFailure('Error Occured'));
    } else {
      const relationShipData = response.data?.data?.relationships?.survey_notification.data;
      const responseData = {
        isDataAvailable: relationShipData !== null,
        id: response.data.data.id,
        amount: relationShipData?.amount || '',
        comments: relationShipData?.comments || '',
        customerName: relationShipData?.customer_name || '',
        datetime: relationShipData?.datetime || '',
        emailAddr: relationShipData?.email_addr || '',
        locationName: relationShipData?.location_name || '',
        mobNumber: relationShipData?.mob_number || '',
        overallExp: relationShipData?.overall_exp || '',
        ppcCard: relationShipData?.ppc_card || '',
        receipt: relationShipData?.receipt || '',
        register: relationShipData?.register || '',
        retailType: relationShipData?.retail_type || '',
        staffId: relationShipData?.staff_id || '',
        store: relationShipData?.store || '',
        surveyId: relationShipData?.survey_id || '',
        customerId: relationShipData?.customer_id,
        completedAt: response.data?.data?.attributes?.completed_at,
        ratingValue: response.data?.data?.rating,
        questions:
          relationShipData?.questions?.length > 0
            ? relationShipData.questions.map((surQueslist: IDashboardSurveyQuestionList) => {
                return {
                  question: surQueslist?.question || '',
                  answer: surQueslist?.answer || ''
                };
              })
            : [],
        resolvedAt: relationShipData?.resolved_at || '',
        resolvedComment: relationShipData?.resolved_comment || ''
      };
      yield put(dashboardLocationDetailedViewResponse(responseData));
    }
  } catch (error: any) {
    yield put(dashboardLocationDetailedViewFailure(error));
  }
}

export function* takeDashboardRequest() {
  yield takeLatest(actionTypes.DASHBOARD_SURVEY_STATS_REQUEST, getDashboardSurveyStatsRequest);
  yield takeLatest(actionTypes.DASHBOARD_REVIEW_STATS_REQUEST, getDashboardReviewStatsRequest);
  yield takeLatest(actionTypes.DASHBOARD_SURVEY_RECENT_COMMENTS_REQUEST, getDashboardRecentCommentsRequest);
  yield takeLatest(actionTypes.DASHBOARD_SURVEY_QUESTION_REQUEST, getDashboardSurveyQuestions);
  yield takeLatest(actionTypes.DASHBOARD_SURVEY_SEND_MESSAGE_REQUEST, getDashboardSurveySendMessageRequest);
  yield takeEvery(actionTypes.DASHBOARD_SURVEY_ANSWER_REQUEST, getDashboardSurveyAnswerRequest);
  yield takeLatest(actionTypes.DASHBOARD_SURVEY_NOTIFICATION_REQUEST, getDashboardSurveyNotificationsRequest);
  yield takeLatest(actionTypes.DASHBOARD_SURVEY_RESOLVED_NOTIFICATION_REQUEST, getDashboardSurveyResolvedNotificationsRequest);
  yield takeLatest(actionTypes.DASHBOARD_RESOLVE_NOTIFICATION_REQUEST, getDashboardResolvedNotificationsRequest);
  yield takeLatest(actionTypes.DASHBOARD_LOCATION_DETAILED_VIEW_REQUEST, getDashboardLocationDetails);
}
