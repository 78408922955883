export const BRAND_PROFILE_SETTINGS_SECTIONS = {
  PREFERRED_LANGUAGE: 'preferred_language',
  BRAND_LOGO: 'brand_logo',
  FEED_BADGE_URL: 'feed_badge_url',
  POST_CARD_LOGO: 'post_card_logo',
  BACKGROUND_IMAGE: 'background_image'
};

export const BRAND_PROFILE_IMAGE_TYPES = {
  BRAND_LOGO: 'brand_logo',
  FEED_BADGE_LOGO: 'feed_badge_logo',
  POST_CARD_LOGO: 'post_card_logo',
  BACKGROUND_IMAGE: 'background_image'
};
